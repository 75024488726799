@import '../../../static/css/common';

.integrationStepsMainDiv {
    padding: 1.5rem 1.5rem 1.5rem 2.2rem;
    color: #212121;

    img {
        width: 100%;
        height: auto;
        margin-top: 1rem;
    }

    .stepsTitle {
        font-size: 1.2rem;
        color: #212121;
        font-weight: 600;
        margin-bottom: 0.5rem;
        font-family: Roboto, "-apple-system", "BlinkMacSystemFont", "Segoe UI", Helvetica, Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
    }

    .instructionText {
        color: #212121;
        font-weight: normal;
        font-size: 18px;
        font-family: Roboto, "-apple-system", "BlinkMacSystemFont", "Segoe UI", Helvetica, Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;

        a {
            display: block;
            margin-top: 1rem;
            background-color: #64BBFA;
            width: 8.5rem;
            padding: 0.5rem 1.5rem;
            text-decoration: none;
            text-align: center;
            font-size: 1rem;
            cursor: pointer;
            outline: none;
            border-radius: 0.25rem;
            color: #ffffff;
            border: none;

            &:hover {
                background: #73c2fb;
            }
        }
    }

    .instructions-list {
        list-style: none;
        padding: 0;
        margin: 0px;
        color: #212121;
        font-weight: normal;

    }

    .instructions-list li {
        margin-bottom: 20px;
        width: 100%;
        float: left;
        line-height: 1.3em;
    }

    .list-seq {
        font-size: 18px;
        color: #eb5757;
        letter-spacing: 0;
        line-height: normal;
        border-radius: 100%;
        display: inline-block;
        text-align: center;
        margin-right: 16px;
        font-weight: bold;
        vertical-align: top
    }

    .instructions-list-nobullet {
        list-style-type: none;
    }

    .instructions-text {
        font-weight: normal;
        font-size: 18px;
        vertical-align: bottom;
        color: #212121;
        letter-spacing: 0;
        line-height: normal;
        font-family: Roboto, "-apple-system", "BlinkMacSystemFont", "Segoe UI", Helvetica, Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
        display: inline-block;
        width: calc(100% - 64px);
    }

    .eventname-instructions-text-start {
        font-weight: normal;
        font-size: 18px;
        vertical-align: bottom;
        color: #212121;
        letter-spacing: 0;
        line-height: normal;
        font-family: Roboto, "-apple-system", "BlinkMacSystemFont", "Segoe UI", Helvetica, Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
        display: inline-block;
        width: calc(100% - 64px);
        padding-top: 10px;
        padding-bottom: 8px;
    }

    .eventname-instructions-text {
        font-weight: normal;
        font-size: 18px;
        vertical-align: bottom;
        color: #212121;
        letter-spacing: 0;
        line-height: normal;
        font-family: Roboto, "-apple-system", "BlinkMacSystemFont", "Segoe UI", Helvetica, Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
        display: inline-block;
        width: calc(100% - 64px);
        padding-bottom: 8px;
    }
}

.wooCommerceIntegrationDiv {
    padding: 0 1.5rem;
    @include commonFormStyle;

    .image {
        width: 100%;
        height: auto;
    }

    .stepsTitle {
        font-size: 1.2rem;
        color: #212121;
        font-weight: 600;
        margin-bottom: 0.5rem;
    }
}

.integrationStyleMainDiv {
    min-width: 32.725rem;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre-line;

    .setting-box-content-con {
        padding: 0 1.5rem;

        @media (min-width: 800px) and (max-width: 1215px) {
            div.listing-box:nth-child(2n) {
                margin-right: 0px !important;
            }

            .commonSettingsBox .listing-box {
                width: 47% !important;
            }
        }

        .floatLeft {
            float: left;
        }
    }
}