$REACT_APP_S3_URL : 'https://trackhive-images.s3-ap-southeast-1.amazonaws.com/';
$grey-0: #434A54;
$grey-1: #7C828A;
$grey-2: #BFC2C7;
$grey-3: #EBECED;
$grey-4: #F9FAFA;
$grey-5: #E0E2E4;
$grey-6:#eff0f1;
$grey-7:#dadde1;
$grey-8:#dcdee0;
$background-grey: #f2f4f5;
$red-0: #F73E5D;
$red-1: #FA7087;
$red-2: #FAAFBB;
$red-3: #FFD9DF;
$red-4: #FFF2F4;
$yellow-0: #FCD200;
$yellow-1: #FFDF40;
$yellow-2: #FFEA80;
$yellow-3: #FFF4BF;
$yellow-4: #FFFBE6;
$orange-0: #F5A41C;
$green-0: #66EB23;
$green-1: #88F054;
$green-2: #ADF788;
$green-3: #D2FCBD;
$green-4: #E6FFD9;
$blue-0: #31A5F7;
$blue-1: #64BBFA;
$blue-2: #99D5FF;
$blue-3: #BFE4FF;
$blue-4: #DEF1FF;
$blue-5: #415277;
$purple-3: #f3e7ff;
$red-muted: #F5C4CC;
$yellow-muted: #F7EFC6;
$green-muted: #CEEDBE;
$blue-muted: #C4E0F5;
$white: #FFFFFF;
$white-1:#F5F6F7;
$brown: #ffdebf;
$pink: #ECD9FF;
$purple: #D9DFFF;
$orange: #FFDABF;
$selectedBorder: rgb(49, 165, 247);
$cardHover: #efefef;
$black-0: #404040;
$light-blue :#506898;
$dark-blue :#212f4e;
$dark-blue-1:#21304e;
$white:#fff;
$cardHover-1:#fffef2;

// font css
$font: '-apple-system',
'BlinkMacSystemFont',
'Segoe UI',
Roboto,
Helvetica,
Arial,
'Apple Color Emoji',
'Segoe UI Emoji',
'Segoe UI Symbol';
$font-regular: 400;
$font-medium: 500;
$font-bold: 700;
$font-label: 0.75rem;
$font-input: 0.875rem;
$input-radius: 4px;

.border-none {
    border: 0 !important;
}

.border-top-none {
    border-top: 0 !important;
}

.border-bottom-none {
    border-bottom: 0 !important;
}

.border-left-none {
    border-left: 0 !important;
}

.border-right-none {
    border-right: 0 !important;
}

.clearFix {
    clear: both;
}

.textCenter {
    text-align: center
}

// Custom Scrollbar css starts
.scrollbar-custom::-webkit-scrollbar {
    width: 5px;
}

.scrollbar-custom::-webkit-scrollbar:horizontal {
    height: 5px;
}

.scrollbar-custom::-webkit-scrollbar-track {
    border-radius: 10px;
}

.scrollbar-custom::-webkit-scrollbar-thumb {
    background-color: #EBECED;
    border-radius: 16px;
}

.scrollbar-custom::-webkit-scrollbar-thumb:hover {
    background-color: #cacaca;
}

.scrollbar-custom::-webkit-scrollbar-thumb:active {
    background-color: #cacaca;
}

.errorBorder {
    border: 1px solid #e83c19 !important;
    display: block;
    border-radius: 4px;
}

.required {
    color: #e83c19 !important;
}

.bluePrimaryButton {
    width: 89%;
    border: none;
    background-color: #397DE8;
    height: auto;
    padding: 0.7em 1.2em;
    color: #FFFFFF;
    cursor: pointer;
    outline: none;
    border-radius: 0.2rem;
    margin: 0 0 1.5em 0;
    font-size: 1.125rem;

    &:hover {
        background-color: #2C6DD5;
    }
}

.error-fonts {
    font-size: 12px !important;
    color: #F73E5D !important;
    letter-spacing: 0;
    line-height: 16px;
    font-weight: 500;
    font-style: italic;
    margin-left: 6px;
}

.error-Border-Message {
    display: block;
    width: calc(100% - 20px) !important;
    border: 1px solid #e83c19 !important;
    border-radius: 4px;
    font-size: 0.875rem;
    color: #434A54;
    letter-spacing: 0.5px;
    line-height: 28px;
    text-indent: 5px;
}

// Custom Scrollbar css ends
//Valiation Message
.validationMessage {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: $font-input;
    color: $white;
    margin-top: 1rem;
    letter-spacing: -0.25px;
    font-weight: 400;

    .success_error {
        width: 100%;
        text-align: center;
    }

    .errorImage,
    .messageText {
        display: inline-block;
    }
}

.commonToolTipDivWrapper {
    width: 20rem;
    position: absolute;
    left: 222px;
    right: 0;
    bottom: 4rem;
    text-align: center;
    z-index: 11111;
    margin: 0 auto;

    .commonToolTipDiv {
        background-color: #f3f3f3;
        padding: 0.5rem;
        display: inline-flex !important;
        border-radius: 0.5rem;
        align-items: center;

        .icon {
            background: url($REACT_APP_S3_URL + 'check-circle-16p.svg') no-repeat left center;
            width: 1.2rem;
            height: 1rem;
            margin-right: 0.7rem;
        }

        .errorIcon {
            background: url($REACT_APP_S3_URL + 'warningIcon.svg') no-repeat left center;
            width: 1.2rem;
            height: 1rem;
            margin-right: 0.7rem;
        }

        .text {
            text-align: left;
            color: $grey-1;
            font-size: 0.75rem;
            font-weight: $font-medium;
            width: 90%;
        }

        .button {
            background-color: $grey-2;
            color: $grey-0;
            padding: 0.5rem;
            font-size: 0.75rem;
            border-radius: 0.25rem;
            text-align: center;
            float: right;
            margin-left: 0.5rem;
            cursor: pointer;
        }
    }
}

@mixin commonActionButton {
    .actionButtons {
        display: -webkit-box;
        border-top: .063em solid $grey-3;
        text-align: center;
        cursor: pointer;

        width: 100%;


        .SecondryButton {
            font-size: 1.125em;
            display: block;
            text-align: center;
            text-decoration: none;
            line-height: 1em;
            width: 49.7%;
            color: $grey-0;
            border-right: .063em solid $grey-3;
            outline: none;
            cursor: pointer;
            padding: .65em 0;
        }

        .PrimaryButton {
            font-size: 1.125em;
            display: block;
            text-align: center;
            text-decoration: none;
            line-height: 1em;
            width: 49.7%;
            color: #31A5F7;
            outline: none;
            cursor: pointer;
            padding: .65em 0;
        }
    }
}



.commonOverlayDiv {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    background: rgba(0, 0, 0, 0.6);
}

@mixin commonFormStyle {
    form {
        .formGroup {
            margin: 0rem 0 0.65rem 0;
            display: flow-root;
            clear: both;

            .formGroupLeft {
                width: 45%;
                float: left;
            }

            .formGroupRight {
                width: 45%;
                float: right;
            }

            img {
                width: 80px;
                height: 80px;
                border-radius: 100%;
            }

            label {
                display: block;
                width: 100%;
                font-size: .75rem;
                color: $grey-1;
                letter-spacing: .22px;
                line-height: 20px;
                margin-left: 5px;
                text-align: left;
            }

            input[type="text"],
            input[type="email"],
            input[type="password"] {
                display: block;
                width: 99%;
                border: 1px solid $grey-2;
                border-radius: 4px;
                font-size: .875rem;
                color: $grey-0;
                letter-spacing: .5px;
                line-height: 30px;
                padding: 0 0 0 5px !important;
                font-family: inherit;


                &:focus {
                    outline: none;
                    border: 1px solid #64bbfa;
                    color: #7c828a;
                    -webkit-box-shadow: 0 0 4px 0 rgba(49, 165, 247, .25) !important;
                    box-shadow: 0 0 4px 0 rgba(49, 165, 247, .25) !important
                }
            }

            .passwordContainer {
                width: 99.3%;
                border: 1px solid $grey-2;
                height: 2rem;
                border-radius: .25rem;

                .passwordHolder {
                    margin-left: 4px;
                    float: left;
                    font-size: 1.875em;
                    color: $grey-0;
                    letter-spacing: .5px;
                    line-height: 16px;
                }

                .changePasswordText {
                    margin-right: 18px;
                    float: right;
                    font-size: .75em;
                    color: $blue-1;
                    text-decoration: underline;
                    cursor: pointer;
                    letter-spacing: .5px;
                    line-height: 32px;
                }
            }

            small {
                display: block;
                width: 100%;
                font-size: 0.75rem;
                color: $grey-2;
                letter-spacing: 0.22px;
                line-height: 25px;
                padding-left: 5px;
                font-weight: $font-regular;
                font-style: italic;
            }
        }
    }
}

.greenText {
    color: #27ae60
}

.underlineText {
    text-decoration: underline;
    color: $green-1
}

.noData {
    font-size: 1.5rem;
    text-align: center;
    color: $grey-1;
    margin: 7rem 0;
}

.warningColorText {
    color: $red-0;
}

.imageUploadMainDiv {
    display: flow-root;
    clear: both;

    .profileImgDiv {
        float: left;
        margin: 0 20px 0 0
    }

    .uploadImgBtnDiv {
        float: left;
        margin-top: 10px;

        .uploadLogoContainer {
            width: 7rem;
            position: relative;

            .uploadLogoBtn {
                width: 7rem;
                height: 2rem;
                display: block;
                background: #EBECED;
                border-radius: 4px;
                font-size: 14px;
                color: #434A54;
                letter-spacing: 0.26px;
                line-height: 32px;
                text-align: center;
                cursor: pointer;
            }

            input {
                border: none !important;
                position: absolute;
                top: 0;
                height: 2rem;
                opacity: 0;
                cursor: pointer;
                display: block;
                width: 99%;
                border-radius: 4px;
                font-size: 0.875rem;
                color: #434A54;
                letter-spacing: 0.5px;
                line-height: 30px;
                padding: 0 0 0 5px !important;
                font-family: inherit;
                margin-right: 10px;
            }


        }

        .removeUploadedLogo {
            width: 7rem;
            cursor: pointer;
            text-align: center;
            margin-top: 14px;
            font-size: 14px;
            color: #BFC2C7;
            letter-spacing: 0.26px;
            font-weight: 500;
        }
    }
}

.hide {
    display: none !important;
}

.borderright_1 {
    border-right: 0.063em solid #EBECED;
}

.commonMinimizeMainDiv {
    .minimizeDiv {
        background: url($REACT_APP_S3_URL + 'minimise-grey.svg') no-repeat top left;
        height: 1.4rem;
        width: 1.4rem;
        cursor: pointer;
        float: right;
        right: 0;
        top: 0;
    }
}

.forgotPasswordLinkMessage {
    color: $grey-1;
    font-size: $font-input;
    text-align: center;
}

.commonBreadcrumbIconStyle {
    vertical-align: top;
    float: left;
    margin-top: 0.3rem;
}

.commonLightGrayText {
    a {
        text-align: left;
        font-weight: 700;
        font-size: 1.125rem;
        color: #c5c5c5;
        letter-spacing: 0;
        line-height: 35px;
        float: left;
        text-decoration: none;
    }
}

.noPadding {
    padding: 0 !important
}

.paddingLeft10PX {
    padding-left: 10px !important;
}

.success-fonts {
    font-size: 12px !important;
    color: #54BB83;
    letter-spacing: 0;
    line-height: 16px;
    font-weight: 500;
    font-style: italic;
    margin-left: 6px;
}