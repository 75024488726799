@import '../css/common';

.PalceHolderWrapper {
    background: #F8F8F8;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.07);
    border-radius: 4px;
    max-width: 75rem;
    min-width: 58rem;
    margin: 0.7rem auto;
    align-self: center;
    position: fixed;
    // top: 47%;
    top: calc(54% - 50px);
    left: 60px;
    right: 0;
    transform: translateY(-50%);
    // overflow: scroll;
    overflow: auto;

    .BannerContainer {
        background: $white;
        //height: 284px;
        border-radius: 4px 4px 0 0;

        img {
            float: right;
        }

        .BannerContent {
            width: 40%;
            float: left;
            padding: 2.2rem 0 2.2rem 2.2rem;

            .BannerTitle {
                font-size: 1.875rem;
                color: $grey-0;
                font-weight: $font-regular;
                letter-spacing: 0;
                margin-bottom: 1rem;
            }

            .BannerText {
                font-size: 1rem;
                color: $grey-1;
                letter-spacing: 0;
                line-height: 24px;
                font-weight: $font-regular;
                margin-bottom: 2rem;
                // height: 130px;
            }

        }
    }

    .FeaturesContainer {
        background: transparent;
        padding: 2.2rem;

        .FeaturesBlockContainer {
            display: flex;
            flex-direction: row;
            margin-bottom: 2rem;

            .FeatureBlock {
                width: 30%;
                margin-right: 5%;

                &:nth-child(3) {
                    margin-right: 0;
                }

                .FeatureTitle {
                    font-size: 1.125rem;
                    color: $grey-0;
                    font-weight: $font-medium;
                    letter-spacing: 0;
                    margin-bottom: 1rem;
                }

                .FeatureText {
                    font-size: 1rem;
                    color: $grey-1;
                    letter-spacing: 0;
                    line-height: 24px;
                    font-weight: $font-regular;

                    ul {
                        margin: 0;
                        padding: 0 1.2rem;

                        li {
                            line-height: 1.2rem;
                            margin-bottom: 0.4rem;
                            font-size: 0.875rem;
                        }
                    }
                }
            }
        }
    }

    .Button {
        background: #F5A623;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
        border-radius: 4px;
        color: $white;
        font-size: 0.750rem;
        font-weight: $font-medium;
        padding: 0.6rem 1rem;
        letter-spacing: 0.5px;
        display: inline-block;
        cursor: pointer;
        text-decoration: none;

        &:hover {
            background: #ffb336;
        }
    }

    .BottomActions {
        display: flex;
        flex-direction: row;

        .ActionBlock {
            background: $white;
            border-radius: 4px;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
            padding: 1rem;
            margin-right: 2%;
            width: 49%;
            color: $grey-0;
            font-size: 1rem;
            font-weight: $font-medium;

            &:nth-child(2) {
                margin-right: 0;
            }

            img {
                float: left;
                margin: 0.2rem 1rem 0 0;
            }

            span {
                display: inline-block;
                margin-top: 0.7rem;
            }

            a.Button {
                float: right;
                cursor: pointer;
            }

        }
    }
}

.shipment_module .Button {
    background: #AFBF00;

    &:hover {
        background: #bdcd0c;
    }
}

.customer_module .Button {
    background: #54DBA2;

    &:hover {
        background: #50f6b0;
    }
}

#inventorystyle .Button {
    background: #EB6D2F;

    &:hover {
        background: #FD864C;
    }
}

.stocktransfer_module .Button {
    background: #01BBCC;

    &:hover {
        background: #36DAE9;
    }
}

.purchase_order_module .Button {
    background: #A26FD4;

    &:hover {
        background: #C48DFA;
    }
}

.returns_module .Button {
    background: #697AD1;

    &:hover {
        background: #8D9CEB;
    }
}

.supplier_module .Button {
    background: #2594E4;

    &:hover {
        background: #43AEFB;
    }
}

.automation_module .Button {
    background: #b96446;

    &:hover {
        background: #d47453;
    }
}

.listing_module .Button {
    background: #66d262;

    &:hover {
        background: #75e271;
    }
}

/* iPad Landscape & Small Desktop */

@media screen and (max-height: 650px),
screen and (max-width: 1300px) and (max-height: 650px) and (min-width: 1024px) {


    #inventorystyle,
    .order_module {
        .PalceHolderWrapper {
            margin: 1.2rem auto;
        }
    }

    .supplier_module {
        .PalceHolderWrapper {
            margin: 0.5rem 1.2rem;
        }
    }

    .stocktransfer_module {
        .PalceHolderWrapper {
            margin: 0.5rem 0.15rem;
        }
    }

    .automation_module {
        .PalceHolderWrapper {
            margin: 0rem 0.15rem;
        }
    }

    .listing_module {
        .PalceHolderWrapper {
            margin: 0 auto;
        }
    }

    .PalceHolderWrapper {
        position: unset;
        transform: unset;
        margin: 1rem 0.2rem;

        .BannerContainer {

            .BannerContent {
                width: 60%;
            }
        }
    }
}

/* iPad Landscape & Small Desktop */
// @media screen and (min-width: 1024px) and (max-width: 1150px) {
//     #inventorystyle .BannerContainer {
//         img { width: 550px;}
//     }
// }
//  @media screen and (min-width: 1024px) and (max-width: 1399px) {
//      .BannerContainer {
//         img { width: 550px;}
//      }
//  }
@media(max-width: 1366px) {
    .PalceHolderWrapper {
        display: table;
        margin: 0 auto !important;
        max-width: 1024px;
        max-height: 768px;
        overflow: scroll;
        position: unset;
        top: 0;
        transform: translateY(0%);

        .BannerContainer {
            img {
                max-width: 50% !important;
                padding-right: 10px;
            }

            .BannerContent {
                width: 80%;
            }
        }
    }

}

@media screen and (max-height: 768px),
screen and (max-width: 1300px) and (min-height: 768px) and (min-width: 1024px) {
    #inventorystyle {
        .PalceHolderWrapper {
            position: fixed !important;
            top: 0;
        }

    }
}

//Enterprise pitch css for Setup

.EnterpriseContentWrapper {
    background: #F8F8F8;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.07);
    border-radius: 4px;
    margin: 0.7rem auto;
    align-self: center;
    border: 1px solid #ececec;

    .BannerContainer {
        background: $white;
        //height: 284px;
        border-radius: 4px 4px 0 0;

        img {
            float: right;
        }

        // .IntegrationImg { background: url(../../setup-react/assets/img/Integrations-img.png) no-repeat bottom right; }
        // .EnterpriseImg { background: url(../../setup-react/assets/img/enterprise-img.png) no-repeat bottom right; }
        .BannerContent {
            padding: 2.7rem 0 2.7rem 2.2rem;

            .BannerTitle {
                font-size: 1.875rem;
                color: $grey-0;
                font-weight: $font-bold;
                letter-spacing: 0;
                margin-bottom: 1rem;
            }

            .BannerText {
                width: 50%;
                font-size: 1rem;
                color: $grey-1;
                letter-spacing: 0;
                line-height: 24px;
                font-weight: $font-regular;
                margin-bottom: 2rem;
                // height: 130px;
            }

        }
    }

    .FeaturesContainer {
        background: transparent;
        padding: 2.2rem;

        .FeaturesBlockContainer {
            margin-bottom: 2rem;

            .FeatureTitle {
                font-size: 1.5rem;
                color: $grey-0;
                font-weight: $font-bold;
                letter-spacing: 0;
                margin-bottom: 1rem;
            }

            .FeatureBlock {
                width: 48%;
                margin-right: 4%;
                float: left;

                .FeatureText {
                    font-size: 1rem;
                    color: $grey-1;
                    letter-spacing: 0;
                    line-height: 24px;
                    font-weight: $font-regular;

                    ul {
                        margin: 0;
                        padding: 0 1.2rem;

                        li {
                            line-height: 1.2rem;
                            margin-bottom: 0.4rem;
                            font-size: 0.875rem;
                        }
                    }
                }
            }
        }
    }

    .Button {
        background: #3ECF8E;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
        border-radius: 4px;
        color: $white;
        font-size: 0.750rem;
        font-weight: $font-medium;
        padding: 0.6rem 1rem;
        letter-spacing: 0.5px;
        display: inline-block;
        cursor: pointer;

        &:hover {
            background: #31BC7E;
        }
    }

    .BottomActions {
        display: flex;
        flex-direction: row;

        .ActionBlock {
            background: $white;
            border-radius: 4px;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
            padding: 1rem;
            width: 100%;
            color: $grey-0;
            font-size: 1rem;
            font-weight: $font-medium;

            img {
                float: left;
                margin: 0.2rem 1rem 0 0;
            }

            span {
                display: inline-block;
                margin-top: 0.7rem;
            }

            a.Button {
                float: right;
                cursor: pointer;
            }

        }
    }
}