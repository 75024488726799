.formAttributeBottom .react-autosuggest__container,.editformAttributeBottom .react-autosuggest__container {
    position: relative;
    float:left;
    margin-right: 0.3125rem;
  }
  
  .formAttributeBottom .list_react-autosuggest__input {
    border: .063rem solid #BFC2C7;
    border-radius: .25rem;
    height: 56px;
    outline: none;
    display: block;
    /* margin-left: .2rem; */
    width: 280px!important;
    padding: 0.45rem 0.7rem 0.45rem 2.5rem!important;
    box-shadow: none!important;
    font-family: Roboto,"-apple-system", "BlinkMacSystemFont", "Segoe UI", Helvetica, Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.16px;
    color: #666666;
    background: #FFFFFF url(../../Setup/assets/img/tagIcon.svg) 4px 9px no-repeat;
    /* color: #434A54; */
  }
  .formAttributeBottom .list_react-autosuggest__input_add_action {
    width: 100% !important
  }
  .editformAttributeBottom .list_react-autosuggest__input{
    border: .063rem solid #BFC2C7;
    border-radius: .25rem;
    height: 56px;
    outline: none;
    width: 280px!important;
    box-shadow: none!important;
    font-family: Roboto,"-apple-system", "BlinkMacSystemFont", "Segoe UI", Helvetica, Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.16px;
    color: #666666;
    padding: 0.45rem 0.7rem 0.25rem 2rem!important;
    background: #FFFFFF url(../../Setup/assets/img/tagIcon.svg) 4px 9px no-repeat;
    /* display: inline-block; */
    /* margin-left: .2rem; */
  }
  .formAttributeBottom .list_react-autosuggest__input--focused,.editformAttributeBottom .list_react-autosuggest__input--focused {
    outline: none;
    /*border: 0.063rem solid #99D5FF !important;*/
  }
  
  .formAttributeBottom .list_react-autosuggest__suggestions-container,.editformAttributeBottom .list_react-autosuggest__suggestions-container {
    display: none;
  }
  
  .formAttributeBottom .list_react-autosuggest__suggestions-container--open,.editformAttributeBottom .list_react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: auto;
    bottom: 100%;
    width: 10rem !important;
    padding: 0.3rem !important;
    box-shadow: 0 2px 10px 5px rgba(0, 0, 0, 0.05);
    border-radius: 0.5rem;
    background-color: #fff;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
    color: #434A54 !important;
  }

  .containerDiv .list_react-autosuggest__suggestions-container--open {
    bottom: unset !important;
    width: inherit !important
  }
  
  .formAttributeBottom .list_react-autosuggest__suggestions-list,.editformAttributeBottom .list_react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    /*font-size: 0.875rem;*/
    font-size: 1.0rem;
  
  }
  
  .formAttributeBottom .list_react-autosuggest__suggestion,.editformAttributeBottom .list_react-autosuggest__suggestion {
    cursor: pointer;
    color: #434A54 !important;
    line-height: 1rem;
    list-style: none;
    padding: 0.4rem 0.3rem !important;
    display: flex;
    align-self: center;
    align-items: center;
    font-size: 0.875rem !important;
    font-family: 'Roboto',"-apple-system", "BlinkMacSystemFont", "Segoe UI", Helvetica, Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",sans-serif;
  }
  .formAttributeBottom .list_react-autosuggest__suggestion:last-child, .editformAttributeBottom .list_react-autosuggest__suggestion:last-child {
    border-top: 0.063rem solid #EBECED;
  }
  .formAttributeBottom .list_react-autosuggest__suggestion:last-child:hover,.editformAttributeBottom .list_react-autosuggest__suggestion:last-child:hover {
    background-color: #fff;
    border-radius: 0rem
  }
  .formAttributeBottom .list_react-autosuggest__suggestion--highlighted,.editformAttributeBottom .list_react-autosuggest__suggestion--highlighted {
    /* background-color: #ddd; */
    background-color: #BFE4FF;
    border-radius: 0.4rem;
  }
  .formAttributeBottom span.ColorPoint,.editformAttributeBottom span.ColorPoint {
    display: block;
    float:left;
    background-color: #F9BCBD;
    border-radius: 2rem;
    border: 4px solid #FFFFFF;
    width: 0.75rem;
    height: 0.75rem;
    margin-right: 0.3rem;
    box-sizing: unset;
    -webkit-box-sizing: unset;
  }
  