@import '../../../static/css/_common';
$none : none !important;

.containerDiv {
    margin: 32px;
    width: 100%;
    #scroll {
        top: 0;
        left: 0;
        height: 100%;
        margin: 0.5rem 0 0 !important;
        width: 100%;
        .tag {
            height: 32px;
            border-radius: 46px;
            background-color: #e0e0e0;
            margin-right: 8px;
            padding: 6px 12px 0px 12px;
            width: max-content;
            display: inline-block;
            margin-top: 8px;
            .text{
                height: 16px;
                font-family: Roboto,"-apple-system", "BlinkMacSystemFont", "Segoe UI", Helvetica, Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",sans-serif;
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: 0.14px;
                color: #212121;
            }
            .closeTag {
                -webkit-mask: url(../../../../src/Setup/assets/img/close.svg) no-repeat left top;
                -webkit-mask-size: cover;
                mask: url(../../../../src/Setup/assets/img/close.svg) no-repeat left top;
                mask-size: cover;
                background-color: #111111;
                height: 0.5rem;
                width: 0.5rem;
                margin-left: 0.5rem;
                cursor: pointer;
                display: inline-block;
            }
        }

        .customEmailDiv {
            width: 90%;
            // margin-top: 0.5rem;
        }
    }
    .tag-position {
        position: absolute;
        width: 11rem;
        height: 9.8rem;
        background: $white;
        box-shadow: 0 0.125rem 0.625rem 0.313rem rgba(0, 0, 0, 0.05);
        padding: 0.2rem;
        border-radius: 0.5rem;

        .title {
            font-size: 0.875rem;
            font-weight: $font-bold;
            color: $grey-0;
            text-align: center;
            border-bottom: 0.063em solid $grey-3;
            padding: 0;
            line-height: 1.5rem;
            float: left;
            width: 100%;
            margin-top: 0.1rem;
            font-family: $font;
        }
    }

    .circle_picker {
        margin: 2rem 0 0 0.5rem;
        width: 170px !important;
    }

    .dd-add-tag {
        background: none;
        border: navajowhite;
        font-size: 14px;
        letter-spacing: 0.26px;
        cursor: pointer;
        color: #31A5F7 !important;
        float: right !important;
        margin-top: 1rem;
        outline: none;
    }

    .dd-cancel-tag {
        background: none;
        border: navajowhite;
        font-size: 14px;
        letter-spacing: 0.26px;
        cursor: pointer;
        color: $grey-2 !important;
        float: left !important;
        margin-top: 1rem;
        outline: none;
    }

    .huepicker {
        width: 9.60rem !important;
        height: 1.0rem !important;
        margin-top: 0.75rem !important;
        margin-left: 0.6rem !important;
        margin-right: 0.75rem !important;
        border-radius: 0.75rem !important;
    }
}

.automationModule {
    margin-top: -48px;
    .tagAction {
        margin: 0.5rem 0 0.25rem 2rem !important;
        float: none !important;

        .colorPicker {
            //position: absolute;
            top: 13.5rem;
            left: 0.25rem;
        }
    }

    #SearchWrapper {
        position: relative;
        float: right;
        margin: 0 18rem 0rem 2rem;

        #scroll {
            top: 0;
            left: 0;
            height: 100%;
            margin: 0.25rem 0 0;
            width: 100%;

            .tag {
                color: #fff;
                font-size: .75rem;
                line-height: 1.35rem;
                padding: 0 .3125rem;
                border-radius: .2rem;
                margin-right: .3125rem;
                margin-top: 0.25rem;
                display: inline-block;
                height: 100%;

                .closeTag {
                    background: url(../img/close-20p.svg) no-repeat left 0.1rem;
                    height: .6rem;
                    width: .6rem;
                    margin-left: .5rem;
                    cursor: pointer;
                    display: inline-block;
                }
            }
        }

        .SearchBack {
            height: 28px;
            float: left;
            margin-right: 0.5rem;

            &:hover {
                background-color: $grey-3;
                border-radius: 0.25rem;
            }
        }

        .SearchInput {
            width: 90%;
            height: 98%;
            background: $grey-3 url(../img/search-28p.svg) no-repeat 0em -0.05em;
            border-radius: 0.25rem;
            border: none;
            padding: 0.4rem 0.5rem 0.4rem 2rem;
            outline: 0;
            font-size: 0.875rem;
            color: $grey-0;
        }

        .ShipmentRow {
            font-size: 0.875rem;
            color: $grey-1;
            text-align: left;
            line-height: 1rem;
            cursor: pointer;
            font-weight: 400;

            span {
                color: $grey-0;
                display: block;
                padding-bottom: 0.3em;
                font-weight: $font-medium;
                font-size: 0.875rem;
            }
        }
  
        span.ColorPoint {
            display: block;
            float: left;
            background-color: #F9BCBD;
            border-radius: 2rem;
            border: 4px solid #FFFFFF;
            width: 1.30rem;
            height: 1.30rem;
            margin-right: 0.3rem;
        }
    }

    .noAutomationStyleDiv {
        .noAutomationInnerDiv {
            color: #bfc2c7;
            text-align: center;
            -ms-flex-item-align: center;
            align-self: center;
            max-width: 50rem !important;
            margin: 0 auto;
            position: absolute;
            left: 60px;
            right: 0;
            top: 50%;
            -webkit-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
            padding: 0 3rem;

            .MsgTitle {
                font-size: 1.85rem;
                width: 18rem !important;
                margin: 0 auto;
                display: flow-root;
            }
        }
    }

    .SmallModalPopupWrapper {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 125;
        background: rgba(0, 0, 0, 0.6);

        &:focus {
            border: none !important;
            outline: none !important;
        }
    }

    .SmallModalContainer {
        &:focus {
            border: none !important;
            outline: none !important;
        }

        width: 20rem;
        position: absolute;
        z-index: 101;
        display: -ms-inline-flexbox;
        display: inline-flex;
        background: #FFFFFF;
        -webkit-box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.05),
        0 5px 30px 5px rgba(0, 0, 0, 0.1);
        box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.05),
        0 5px 30px 5px rgba(0, 0, 0, 0.1);
        border-radius: 0.5rem;
        top: 50%;
        left: 0;
        right: 0;
        margin: 0 auto;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);

        .titleDiv {
            border-bottom: 0.06rem solid #EBECED;
            width: 100%;
            height: 1.75rem;
            padding: 0.5rem 0;

            .title {
                font-size: 1rem;
                font-weight: 700;
                color: #434A54;
                text-align: center;
                border: none;
                padding: 0;
                line-height: 1.5rem;
                width: 100%;
                font-family: Roboto,"-apple-system", "BlinkMacSystemFont", "Segoe UI", Helvetica, Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",sans-serif;
            }
        }

        .ModalContent {
            font-size: 0.85rem;
            font-weight: 500;
            color: #434A54;
            text-align: center;
            line-height: 1.5rem;
            margin: 1.125rem;
        }
    }

    .cust_list_style ul {
        list-style: none;
    }

    .cust_list_style li {
        position: relative;
        counter-increment: decimal;
        padding: 10px 0;
    }

    .cust_list_style li:before {
        position: absolute;
        font-size: 16px;
        color: $grey-0;
        left: -20px;
        top: 10px;
        content: counter(decimal) ". ";
        width: 20px;
        height: 20px;
    }

    #ModalPopupWrapper {
        background: #FFFFFF;
        border-radius: 0.5rem;
        width: 20rem;
        z-index: 1000;
        -webkit-box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.05), 0 5px 30px 5px rgba(0, 0, 0, 0.1);
        box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.05), 0 5px 30px 5px rgba(0, 0, 0, 0.1);

        &:focus {
            border: none !important;
            outline: none !important;
        }

        .ActionButtons {
            display: -webkit-box;
            border-top: 0.063em #EBECED solid;
            text-align: center;
            margin-top: 1rem;
            cursor: pointer;

            a.SecondryButton,
            a.PrimaryButton
                {
                font-size: 1rem;
                display: block;
                text-align: center;
                text-decoration: none;
                padding: 0.65em 0rem;
                width: 49.7%;
            }

            a.SecondryButton {
                color: #434A54;
                cursor: pointer;
            }

            a.PrimaryButton {
                color: #31A5F7;
            }
        }
    }

    .dataContainer {
        .filterBlock {
            margin: 0.5rem 0rem 0rem 0.3rem;
            display: inline-block;
            width: 100%;

            .noFilterFound {
                font-size: 1rem;
                text-align: center;
                color: $grey-1;
                padding: 0 0 0.5rem 0;
            }

            .filterWrapper {
                position: relative;
                display: inline-block;

                .filterDropdown {
                    .filterText.heading {
                        font-size: 14px;
                        color: #ffffff;
                        font-weight: 500;
                        background-color: #1a73e8;
                        /* padding-left: 0.5rem; */
                        text-align: left;
                        vertical-align: middle;
                        font-style: Roboto;
                        font-family: Roboto,"-apple-system", "BlinkMacSystemFont", "Segoe UI", Helvetica, Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",sans-serif;
                        height: 38px;
                        padding-top: 10px;
                        // padding-bottom: 0.4em;
                        padding-left : 15px
                    }
                }

                .FilterBtnDD {
                    float: left;
                    // margin: 0 .5rem .2rem 0;
                    position: relative;
                }

                .filterButton {
                    float: left;
                    // padding: 0 0.7rem 0 0.6rem;
                    //padding: 0 0 0 .4rem;
                    cursor: pointer;
                    display: -ms-flexbox;
                    display: flex;
                    display: -webkit-flex;
                    //background: $grey-6;
                    //border: 1px solid $grey-7;
                    //border-radius: .2rem;
                    height: 16px;
                    font-family: Roboto,"-apple-system", "BlinkMacSystemFont", "Segoe UI", Helvetica, Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",sans-serif;
                    font-size: 14px;
                    font-weight: 500;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: 0.14px;
                    color: #727272;
                    padding-left: 32px;

                    &:hover {
                        background: $white;
                    }

                    .filterArrow {
                        background: url($REACT_APP_S3_URL+'filterDownIcon.svg') no-repeat left top;
                        width: 24px;
                        // height: 1.750rem;
                        height: 24px;
                        display: block;
                        margin-top: -3px;
                        object-fit: contain;

                    }
                }

                .filterDropdown {
                    background: $white;
                    -webkit-box-shadow: 0rem 0.06rem 0.125rem 0.05rem $grey-3;
                    box-shadow: 0rem 0.06rem 0.125rem 0.01rem #D4BDBD;
                    position: absolute;
                    top: -0.7rem;
                    // left: -0.65rem;
                    left: 18px;
                    z-index: 20;
                    //padding: 0 0.63rem 0rem 0.63rem;
                    width: 250px;
                    border-radius: 0rem;

                    .heading {
                        // border-bottom: 0.06rem $grey-3 solid;
                        //margin-bottom: 0.5rem;

                        a {
                            color: #ffffff;
                            font-size: $font-input;
                            text-decoration: none;
                            float: right;
                            cursor: pointer;
                            padding-right : .5rem
                        }
                    }

                    .footer {
                        // margin-bottom: 0.5rem;

                        a {
                               font-size: 14px;
                                float: right;
                                cursor: pointer;
                                padding-right: 1.5rem;
                                color: #1a73e8;
                                padding-top: 9px;
                                padding-bottom: 0.5rem;
                                font-weight: 500;
                                height: 38px
                        }
                    }

                    .filterSearchDiv {
                        .searchFilter {
                            width: 100%;
                           // padding: 0.2rem;
                            margin-bottom: 0.5rem;
                            //border: 0.063rem solid #EBECED;
                            border-radius: -15rem 0.2rem 0.2rem 0.2rem;
                            /* background-color: #EBECED !important; */
                            background: url(https://trackhive-images.s3-ap-southeast-1.amazonaws.com/search.svg) no-repeat 14.2em 1rem;
                            font-size: 12px;
                            color: #434A54;
                            /* padding-left: 0.5rem; */
                            height: 40px;
                            //background-size: 20px;
                            &:focus {
                                border: 0.063rem solid #EBECED;
                                outline: none;
                            }
                        }
                    }

                    .commonDropdownMenu {
                        max-height: 270px;
                        overflow-x: hidden;
                        overflow-y: auto;

                        ul {
                            list-style: none !important;
                            margin: 0 !important;
                           // padding: 0 !important;
                            padding-top : 9px;

                            li {
                                line-height: 1.50em;
                                margin: 0;
                                padding: 0.1rem 0 !important;
                                font-weight: 500;

                                label {

                                    color: #434a54;
                                    font-size: .75rem;
                                    text-decoration: none;
                                    cursor: pointer;
                                    display: block;
                                    width: 100%;
                                    -o-text-overflow: ellipsis;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    max-width: 195px;
                                    padding-left: 2px;
                                    line-height: 1rem;

                                    input {
                                        width: 13px !important;
                                        margin: 0.5em;
                                        float: left;
                                    }

                                    .spanText {
                                        display: block;
                                        float: left;
                                        line-height: 1.6rem;
                                        width: 80%;
                                        white-space: nowrap;
                                        overflow: hidden;
                                        -o-text-overflow: ellipsis;
                                        text-overflow: ellipsis;
                                        text-align: left;
                                    }
                                }
                            }
                        }
                    }
                    .commonDropdownMenuStatic {
                        max-height: 201px;
                        overflow-x: hidden;
                        overflow-y: auto;

                        ul {
                            list-style: none !important;
                            margin: 0 !important;
                           // padding: 0 !important;
                            padding-top : 0px;

                            li {
                                line-height: 1.50em;
                                margin: 0;
                                padding: 0.1rem 0 !important;
                                font-weight: 500;

                                label {

                                    color: #434a54;
                                    font-size: .75rem;
                                    text-decoration: none;
                                    cursor: pointer;
                                    display: block;
                                    width: 100%;
                                    -o-text-overflow: ellipsis;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    max-width: 195px;
                                    padding-left: 2px;
                                    line-height: 1rem;

                                    input {
                                        width: 13px !important;
                                        margin: 0.5em;
                                        float: left;
                                    }

                                    .spanText {
                                        display: block;
                                        float: left;
                                        line-height: 1.6rem;
                                        width: 80%;
                                        white-space: nowrap;
                                        overflow: hidden;
                                        -o-text-overflow: ellipsis;
                                        text-overflow: ellipsis;
                                        text-align: left;
                                    }
                                }
                            }
                        }
                    }

                    .transitDaysRange {
                        min-height: 6.8rem;

                        ul {
                            font-size: 0.75rem;
                            color: #434A54;
                            cursor: pointer;
                            font-weight: 400;
                            text-align: center;
                            text-decoration: none;
                            list-style: none;
                            margin: 0;
                            padding: 0;

                            li {
                                line-height: 1.50em;
                                list-style: none;
                                margin: 0;
                                padding: 0;

                                &:hover {
                                    background-color: #BFE4FF !important;
                                    border-radius: 4px;
                                }
                            }
                        }
                    }
                    .custom-date-range-done {
                        padding: 4px 2px;
                        color: #fff;
                        background-color: #31a5f7;
                        text-align: center;
                        border-radius: 4px;
                        font-weight: 300;
                        line-height: 1.5rem;
                        cursor: pointer;
                      }
                }
            }
            .actionIconWrapper{
                float: right;
                margin-right: 1.6rem;
                .actionIconImg{
                    display: inline-grid;
                    padding-left: 1.7rem;
                    text-align: -webkit-center;
                    cursor: pointer;
                }
                .actionIconText{
                    height: 14px;
                    font-family: Roboto,"-apple-system", "BlinkMacSystemFont", "Segoe UI", Helvetica, Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",sans-serif;
                    font-size: 12px;
                    font-weight: 500;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: 0.12px;
                    color: #727272;
                    padding-top: 3px;
                }
            }
            .selectedFiltersListDiv {
                display: inline;
                padding-left: 32px;
                vertical-align: top;
                //padding-top: 10PX;
                .Tags {
                    font-size: 14px;
                    padding: 0.4em 0.5em 0.4em 0.6em;
                    display: inline-block;
                    height: 32px;
                    border-radius: 16px;
                    background-color: #e0e0e0;
                    margin: 4px .5rem 0 0;
                }
                .tagText{
                    height: 16px;
                    font-family: Roboto,"-apple-system", "BlinkMacSystemFont", "Segoe UI", Helvetica, Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",sans-serif;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: 0.14px;
                    color: #212121;
                }

                .closeTag {
                    background: url($REACT_APP_S3_URL+ 'cancel.svg') no-repeat left -0.15em;
                    height: 1.375em;
                    width: 1.5em;
                    display: block;
                    margin-left: 0.5em;
                    float: right;
                    cursor: pointer;
                }
            }
        }
        .automationPopupMainDiv {
            .addNewAutomationWrapper {
                min-height: 25rem;
                overflow: hidden;
                padding: 0rem 0rem 1.5rem;

                .automationFilterMainDiv {
                    padding: 1rem 2rem;

                    .dateMainDiv, .subUserMainDiv{
                        display: inline-block;
                        vertical-align: top;
                        width: 25%;
                    }

                    .closeDiv{
                        display: inline-block;
                        font-size: 1.5rem;
                        vertical-align: middle;
                        margin: 1.5rem 0 0 1rem;
                        color: $grey-1;
                        cursor: pointer;
                    }

                    input {

                        &:focus,
                        &:active {
                            outline: none !important;
                        }
                    }

                    

                    .custom-date-range-done {
                        padding: 4px 2px;
                        color: #FFFFFF;
                        background-color: #31A5F7;
                        text-align: center;
                        border-radius: 4px;
                        font-weight: 300;
                        line-height: 1.5rem;
                        cursor: pointer;
                    }

                    .filterDropdown {
                        background: #FFFFFF;
                        -webkit-box-shadow: 0em 0.06em 0.125em 0.05em #EBECED;
                        box-shadow: 0em 0.06em 0.125em 0.05em #EBECED;
                        position: absolute;
                        top: 0rem;
                        left: -0.55rem;
                        z-index: 5;
                        border-radius: 0.5rem;
                        padding: 0 0.63em 0.63rem;
                        min-width: 12.50rem;
                        margin: 2rem 0 0 2rem;
                    }

                    .title {
                        font-size: 0.750rem;
                        color: #7C828A;
                        line-height: 1.6rem;
                        display: block;
                    }

                    .heading {
                        display: block;
                        font-size: .875rem;
                        color: #434A54;
                        line-height: 1.8rem;
                        font-weight: 400;
                        letter-spacing: -.04rem;
                    }

                    .closeLink {
                        color: #31A5F7;
                        font-size: .875rem;
                        text-decoration: none;
                        float: right;
                    }
                }

                .message {
                    display: -ms-flexbox;
                    display: flex;
                    -ms-flex-align: center;
                    align-items: center;
                    -ms-flex-pack: center;
                    justify-content: center;
                    font-size: 0.875rem;
                    color: #FFFFFF;
                    margin-top: 1rem;
                    letter-spacing: -0.25px;
                    font-weight: 400;
                }

                .automationTitle {
                    font-size: 1.125rem;
                    color: $white;
                    line-height: 1.25rem;
                    font-weight: 700;
                    padding: 0 1rem;
                    margin-bottom: 0.5rem;
                }

                .automationDetailContainer {
                    position: relative;
                    overflow: auto;
                    overflow-x: hidden;
                    overflow-y: auto;
                    background: $white;
                    -webkit-box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.05), 0 5px 30px 5px rgba(0, 0, 0, 0.1);
                    box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.05), 0 5px 30px 5px rgba(0, 0, 0, 0.1);
                    border-radius: 0.5rem;
                    min-height: 460px;

                    .ScrollerWrapper {
                        margin-top: 1.25rem;

                        textarea {
                            background: #FFFFFF;
                            font-size: 0.875rem;
                            color: #434A54;
                            border: 0.063rem solid #BFC2C7;
                            border-radius: 0.25rem;
                            height: 4rem;
                            outline: none;
                            padding: 0.2rem 0.4rem;
                            margin-bottom: 0.25rem;
                        }

                        .ScrollerContent {
                            padding: 0 0.75rem 0 0.75rem;

                            .titleBar {
                                clear: both;
                                margin-bottom: 2rem;
                                @include commonFormStyle;

                                .Title {
                                    font-size: 1.125rem;
                                    color: #434A54;
                                    float: left;
                                    margin: 0 0.5rem;
                                }

                                input {
                                    width: 30% !important;
                                }

                                .Switch {
                                    float: right;
                                    font-size: 0.875rem;
                                    color: #434A54;
                                    margin-right: 0.5rem;

                                    .switch.on {
                                        background: #66EB23 !important;
                                        border: 1px solid #66EB23 !important;
                                    }

                                    .switch {
                                        border: 1px solid #BFC2C7 !important;
                                        width: 26px !important;
                                        height: 16px !important;
                                        border-radius: 13px;
                                        cursor: pointer;
                                        display: inline-block;
                                    }

                                    .switch.on .switch-toggle {
                                        left: 10px !important;
                                    }

                                    .switch-toggle {
                                        border: 1px solid #BFC2C7 !important;
                                        width: 14px !important;
                                        height: 14px !important;
                                        -webkit-box-shadow: none;
                                        box-shadow: none;
                                        left: 0;
                                        border-radius: 12px;
                                        background: #fff;
                                        position: relative;
                                        -webkit-transition: left .2s ease-in-out;
                                        -o-transition: left .2s ease-in-out;
                                        transition: left .2s ease-in-out;
                                    }
                                }
                            }

                            .subTitle {
                                font-size: 0.875rem;
                                color: $grey-0;
                                margin-bottom: 0.5rem;
                                padding: 0.25rem 0.5rem;
                                border-bottom: 0.063rem solid #EBECED;
                            }

                            .contentText {
                                margin: 0 0.5rem 2rem;

                                .cardRow {
                                    font-size: 0.875rem;
                                    color: #7C828A;
                                    padding: 0 0 0.4rem 0;
                                    line-height: 1.125rem;

                                    .tag {
                                        color: #fff;
                                        font-size: .85rem;
                                        line-height: 1.35rem;
                                        padding: 0 .3125rem;
                                        border-radius: .2rem;
                                        margin-right: .3125rem;
                                        margin-top: 0.25rem;
                                        display: inline-block;
                                        height: 100%;
                                    }

                                    .addActionCardRowDepth {
                                        margin-left: 2rem;
                                        // width: 15%;
                                        display: inline-block;
                                        vertical-align: top;
                                    }

                                    .greyTag {
                                        background-color: #EBECED;
                                        border-radius: 0.3rem;
                                        padding: 0.1rem 0.3rem 0.2rem 0.3rem;
                                        color: #434A54;
                                        font-size: 0.750rem;
                                    }

                                    .addActionCardRowDepth .ColumnInvoice.Label {
                                        font-size: 0.75rem;
                                        color: #BFC2C7;
                                    }

                                    .ColumnInvoice {
                                        width: auto;
                                        float: left;
                                    }

                                    .ColumnInvoice.ServiceName {
                                        font-size: 0.875rem;
                                        color: #434A54;
                                        line-height: 1.6rem;
                                        width: auto;
                                        margin-right: 1%;
                                    }

                                    .trimColumnData {
                                        overflow: hidden;
                                        -o-text-overflow: ellipsis;
                                        text-overflow: ellipsis;
                                        white-space: nowrap;
                                    }

                                    .columnTrigger {
                                        display: inline-block;
                                        width: 15rem;
                                    }
                                }
                            }

                            .ColumnCondition {
                                width: 6.2rem;
                                text-align: center;
                                vertical-align: middle;
                                padding: 0 0 0 0.2rem;
                                display: inline-block;
                            }

                            .conditionDiv {
                                .anyOrAllDiv {
                                    .reactSelectCSSMainDiv {

                                        .css-2b097c-container .css-yk16xz-control,
                                        .css-2b097c-container .css-1pahdxg-control {
                                            width: 6rem !important;
                                        }

                                        .css-1ml51p6-MenuList div {
                                            text-align: left;
                                        }

                                        .css-26l3qy-menu {
                                            width: 40% !important;
                                        }
                                    }
                                }
                            }

                            .ColumnAddCondition {
                                width: 19%;
                                vertical-align: top;
                                padding: 0 0 0 0.5rem;
                                display: inline-block;

                                .reactSelectCSSMainDiv {
                                    width: 40%;
                                    display: inline-block;
                                    vertical-align: top;
                                }

                                .tag-position {
                                    position: absolute;
                                    margin-top: -12rem;
                                    //left: 0.5rem;
                                    width: 11rem;
                                    height: 9.8rem;
                                    background: $white;
                                    box-shadow: 0 0.125rem 0.625rem 0.313rem rgba(0, 0, 0, 0.05);
                                    padding: 0.2rem;
                                    border-radius: 0.5rem;

                                    .title {
                                        font-size: 0.875rem;
                                        font-weight: $font-bold;
                                        color: $grey-0;
                                        text-align: center;
                                        border-bottom: 0.063em solid $grey-3;
                                        padding: 0;
                                        line-height: 1.5rem;
                                        float: left;
                                        width: 100%;
                                        margin-top: 0.1rem;
                                        font-family: $font;
                                    }
                                }

                                .circle_picker {
                                    margin: 2rem 0 0 0.5rem;
                                    width: 170px !important;
                                }

                                .dd-add-tag {
                                    background: none;
                                    border: navajowhite;
                                    font-size: 14px;
                                    letter-spacing: 0.26px;
                                    cursor: pointer;
                                    color: #31A5F7 !important;
                                    float: right !important;
                                    margin-top: 1rem;
                                    outline: none;
                                }

                                .dd-cancel-tag {
                                    background: none;
                                    border: navajowhite;
                                    font-size: 14px;
                                    letter-spacing: 0.26px;
                                    cursor: pointer;
                                    color: $grey-2 !important;
                                    float: left !important;
                                    margin-top: 1rem;
                                    outline: none;
                                }

                                .huepicker {
                                    width: 9.60rem !important;
                                    height: 1.0rem !important;
                                    margin-top: 0.75rem !important;
                                    margin-left: 0.6rem !important;
                                    margin-right: 0.75rem !important;
                                    border-radius: 0.75rem !important;
                                }

                                .customEmailDiv {
                                    width: 90%;
                                    // margin-top: 0.5rem;
                                }
                                .hoursAndDaysInput {
                                    width: 40%;
                                    margin-left: 0.7rem;
                                    background: #fff;
                                    font-size: .875rem;
                                    color: #434a54;
                                    border: .093rem solid #ebeced;
                                    border-radius: .25rem;
                                    outline: none;
                                    padding: .3rem .4rem;
                                    display: inline-block;
                                }
                            }

                            .addMoreAnchorTag {
                                padding-left: 0.5rem;
                                font-size: 0.875rem;
                                color: #7C828A;
                                letter-spacing: 0.3px;
                                line-height: 1.75rem;
                                text-decoration: underline;
                                vertical-align: middle;
                            }

                            .imageAlign {
                                vertical-align: middle;
                                margin-left: -5px;
                                cursor: pointer;
                            }

                            .commaSeperatedList {
                                width: inherit;
                                vertical-align: middle;
                                resize: none;
                                min-height: 65px !important;

                                &:focus {
                                    border: 0.063rem solid #64BBFA !important;
                                    -webkit-box-shadow: 0 0 4px 0 rgba(0, 144, 247, 0.25);
                                    box-shadow: 0 0 4px 0 rgba(0, 144, 247, 0.25);
                                }
                            }
                        }
                    }
                }

                .automationMessage {
                    display: -ms-flexbox;
                    display: flex;
                    -ms-flex-align: center;
                    align-items: center;
                    -ms-flex-pack: center;
                    justify-content: center;
                    font-size: 0.875rem;
                    color: $white;
                    margin-top: 1rem;
                    letter-spacing: -0.25px;
                    font-weight: 400;
                }
            }
        }

        .data_scroller_wrapper {
            height: auto;
            overflow-x: auto;
            overflow-y: hidden;
            // margin: 1rem;
            // margin: 0.5rem;

            .dataScroller {
                // max-width: 117rem;
                height: 100%;
                clear: both;
                display: block;

                .card_normal {
                    background: #FFFFFF;
                    width: 20rem;
                    height: 14rem;
                    -webkit-box-sizing: border-box;
                    box-sizing: border-box;
                    border-radius: 0.5rem;
                    font-family: Roboto,"-apple-system", "BlinkMacSystemFont", "Segoe UI", Helvetica, Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",sans-serif;
                    float: left;
                    padding-bottom: 0.575rem;
                    cursor: pointer;
                    // -webkit-box-shadow: 0rem 0.063rem 0.125rem 0.063rem rgba(0, 0, 0, 0.05);
                    // box-shadow: 0rem 0.063rem 0.125rem 0.063rem rgba(0, 0, 0, 0.05);
                    border: 1px solid #e8e9ea;
                    margin: 0 1rem 1rem 0.2rem;

                    .statusBar {
                        border-radius: 0.4rem 0.4rem 0 0;
                        line-height: 1.40rem;
                        padding: 0 0.625rem;
                        font-size: 0.750rem;
                        color: #434A54;
                    }

                    .BorderBotm {
                        border-bottom: 0.063rem solid #EBECED;
                    }

                    .active {
                        background-color: #D2FCBD;
                    }

                    .inactive {
                        background-color: #d3d3d3;
                    }

                    .statusBar {
                        border-radius: 0.4rem 0.4rem 0 0;
                        line-height: 1.40rem;
                        padding: 0 0.625rem;
                        font-size: 0.750rem;
                        color: #434A54;
                    }

                    .CardDataContainer {
                        height: 11.5rem;
                        overflow: hidden;

                        .CardRow {
                            font-size: 0.875rem;
                            color: #7C828A;
                            padding: 0.3rem 0 0.4rem 0;
                            line-height: 1.125rem;

                            ul {
                                margin: 0;
                                padding: 0 1.2rem;

                                .automationDataList {
                                    display: block;
                                    width: 98%;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                    overflow: hidden;
                                }
                            }
                        }

                        .greyTag {
                            background-color: #EBECED;
                            border-radius: 0.3rem;
                            padding: 0.1rem 0.3rem 0.2rem 0.3rem;
                            color: #434A54;
                            font-size: 0.750rem;
                        }
                    }

                    .CardDetail {
                        padding: 0.5rem;

                        .CardTitle {
                            font-size: 1.125rem;
                            color: #434A54;
                            float: left;
                            max-width: 17rem;
                            -o-text-overflow: ellipsis;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            white-space: nowrap;
                            padding-bottom: 0.5rem;
                        }

                        .switch.on .switch-toggle {
                            left: 10px !important;
                        }

                        .switch-toggle-con {
                            float: right;
                            margin: 0.1rem 0.25rem 0 0;
                        }

                        .switch {
                            border: 1px solid #BFC2C7 !important;
                            width: 26px !important;
                            height: 16px !important;
                            border-radius: 13px;
                            cursor: pointer;
                            display: inline-block;
                        }

                        .switch.on {
                            background: #66EB23 !important;
                            border: 1px solid #66EB23 !important;
                        }

                        .switch-toggle {
                            border: 1px solid #BFC2C7 !important;
                            width: 14px !important;
                            height: 14px !important;
                            -webkit-box-shadow: none;
                            box-shadow: none;
                            left: 0;
                            border-radius: 12px;
                            background: #fff;
                            position: relative;
                            -webkit-transition: left .2s ease-in-out;
                            -o-transition: left .2s ease-in-out;
                            transition: left .2s ease-in-out;
                        }
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 1200px) and (min-width: 768px) {
        .skylight-dialog {
            width: 60rem !important;
        }
    }

    .reactSelectCSSMainDiv {
        input {
            &:focus {
                box-shadow: none !important;
            }
        }

        .css-2b097c-container {
            width: 15rem;

            .css-1uccc91-singleValue {
                font-size: $font-input;
                color: $grey-0;
            }

            .css-yk16xz-control {
                border: $none;
                background: $none;
            }

            .css-1okebmr-indicatorSeparator {
                background: $none;
            }

            .css-1pahdxg-control {
                border: $none;
                box-shadow: $none;
                background: $none;

                :hover {
                    border: $none;
                    background: $none;
                }
            }

            .css-1gtu0rj-indicatorContainer {
                color: hsl(0, 0%, 80%)
            }
        }

        .css-26l3qy-menu {
            margin-top: 0px;

            .css-1ml51p6-MenuList {
                div {
                    font-size: $font-input;
                    color: $grey-1
                }

                .css-9gakcf-option {
                    background: white;

                    &:hover {
                        background: #DEEBFF;
                    }
                }
            }
        }

        .css-1wa3eu0-placeholder {
            font-size: $font-input;
        }
    }
    .reactSelectCSSMainDivForActionType {
        vertical-align: middle;
        display: inline-block;
        input {
            &:focus {
                box-shadow: none !important;
            }
        }

        .css-2b097c-container {
            width: 6rem;

            .css-1uccc91-singleValue {
                font-size: $font-input;
                color: $grey-0;
            }

            .css-yk16xz-control {
                border: $none;
                background: $none;
            }

            .css-1okebmr-indicatorSeparator {
                background: $none;
            }

            .css-1pahdxg-control {
                border: $none;
                box-shadow: $none;
                background: $none;

                :hover {
                    border: $none;
                    background: $none;
                }
            }

            .css-1gtu0rj-indicatorContainer {
                color: hsl(0, 0%, 80%)
            }
        }

        .css-26l3qy-menu {
            margin-top: 0px;

            .css-1ml51p6-MenuList {
                div {
                    font-size: $font-input;
                    color: $grey-1
                }

                .css-9gakcf-option {
                    background: white;

                    &:hover {
                        background: #DEEBFF;
                    }
                }
            }
        }

        .css-1wa3eu0-placeholder {
            font-size: $font-input;
        }
    }
}

@media only screen and (max-width: 1600px) and (min-width: 1300px) {
    .addNewAutomationWrapper {
        width: 75rem !important;
    }

    .skylight-dialog {
        width: 75rem !important;
    }
}

.addAutomationFilterWrapperMainDiv {
    .filterspan {
        display: block;
        float: left;
        line-height: 1.6rem;
        width: 80%;
        white-space: nowrap;
        overflow: hidden;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        text-align: left;
    }

    .filterInput {
        width: 13px;
        margin: 0.45rem .5rem 0 0;
        float: left;
    }

    .filterWrapper {
        margin: 0.1em 0.5em 0em 0;

        .TagGrey {
            background: #7c828a !important;
        }

        .Tag {
            padding: 0.1em 0.5em 0.1em 0.5rem;
            color: #FFFFFF;
            display: inline-block;
            margin-right: 0.4rem;
            margin-bottom: 0.2rem;
            font-size: 0.75rem;
            border-radius: 4px;
        }

        .closeTag {
            background: url($REACT_APP_S3_URL+'closeFilter.svg') no-repeat left 0.3rem;
            height: 1.375em;
            width: 0.813em;
            display: block;
            margin-left: 0.5em;
            float: right;
            cursor: pointer;
        }

        .filterParent {
            position: relative;
            display: table;
            float: left;
            cursor: pointer;

            .filterButton {
                float: left;
                /* margin-right: 0.5rem; */
                padding: 0 0.7em 0 0.6rem;
                cursor: pointer;
                display: -ms-flexbox;
                display: flex;
                display: -webkit-flex;

                .FilterArrow {
                    background: url($REACT_APP_S3_URL+'filterDownTriangle.svg') no-repeat left;
                    width: 1.50rem;
                    height: 1.5rem;
                    display: block;
                    margin-top: 2px;
                }

                .FilterText {
                    display: block;
                    font-size: 0.875rem;
                    color: #7C828A;
                    line-height: 1.75rem !important;
                    font-weight: 500;
                    letter-spacing: -0.04rem;
                }
            }

            .filterDropdown {
                background: #FFFFFF;
                -webkit-box-shadow: 0em 0.06em 0.125em 0.05em #EBECED;
                box-shadow: 0em 0.06em 0.125em 0.05em #EBECED;
                position: absolute;
                top: 0rem;
                left: 0;
                z-index: 5;
                border-radius: 0.5rem;
                padding: 0 0.63em 0.63rem;
                min-width: 12.50rem;

                .FilterText {
                    display: block;
                    font-size: 0.875rem;
                    color: #7C828A;
                    line-height: 1.75rem !important;
                    font-weight: 500;
                    letter-spacing: -0.04rem;

                    a {
                        float: right;
                    }
                }

                .searchFilter {
                    width: 100%;
                    padding: 0.2rem;
                    margin-bottom: 0.5rem;
                    border: 0.063rem solid #EBECED;
                    border-radius: 0.2rem;
                    background: url($REACT_APP_S3_URL+ 'search.svg') no-repeat 0.25em -0.25rem;
                    font-size: 0.875rem;
                    color: #434A54;
                    padding-left: 1.5rem;
                    outline: none;
                }

                .DDContainer {
                    max-height: 9rem;
                    overflow-x: hidden;
                    overflow-y: scroll;

                    ul {
                        list-style: none !important;
                        margin: 0 !important;
                        padding: 0 !important;

                        li {
                            list-style: none !important;
                            padding-bottom: 0 !important;

                            label {
                                color: #434A54;
                                font-size: 0.875rem;
                                text-decoration: none;
                                cursor: pointer;
                                display: block;
                                width: 100%;
                                -o-text-overflow: ellipsis;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                overflow: hidden;
                                max-width: 195px;
                                padding-left: 2px;
                                line-height: 1rem;

                                input[type=checkbox] {
                                    width: 13px;
                                    margin: 0.45rem .5rem 0 0;
                                    float: left;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    //AutoSuggest Design Starts
    #SearchWrapper .react-autosuggest__container {
        float: left;
        border: none;
        padding: 0;
        line-height: 0;
        z-index: 10;
        width: 100% !important;
    }

    .noAutomationData {
        font-size: 0.875rem;
        color: #7C828A;
        text-align: center;
        line-height: 1rem;
        font-weight: 400;
        position: absolute;
        background: white;
        width: 23.5rem;
        left: 0rem;
        top: 2rem;
        z-index: 101;
        border-radius: 0.2rem;
        padding: 0.5rem;
        -webkit-box-shadow: 0 2px 10px 5px rgba(0, 0, 0, 0.05);
        box-shadow: 0 2px 10px 5px rgba(0, 0, 0, 0.05);
    }

    #SearchWrapper .automationSearchreact-autosuggest__suggestions-container--loader {
        position: relative;
        z-index: 10;
        width: 103%;
    }

    #SearchWrapper .react-autosuggest__input--focused {
        outline: none;
    }

    #SearchWrapper .react-autosuggest__input--open {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    #SearchWrapper .react-autosuggest__suggestions-container {
        width: 97% !important;
        padding: 0em;
        margin: 0em;
        //position: relative;
        overflow: auto;
        background: #FFFFFF;
        z-index: 1000;
        //width: 100%;
        margin-top: 0.25rem
    }

    #SearchWrapper .react-autosuggest__suggestions-list {
        margin: 0;
        padding: 0 0.15rem;
        list-style-type: none;
        font-size: 0.875em;
        padding-top: 0.3rem;
        width: 95%;
    }

    #SearchWrapper .react-autosuggest__suggestion {
        cursor: pointer;
        color: #434A54;
        line-height: 1em;
        list-style: none;
        display: flex;
        align-self: center;
        align-items: center;
        margin-bottom: 0.4rem;
        padding: 0.5rem 0.4rem;
        width: 100%;
    }

    #SearchWrapper li.react-autosuggest__suggestion--highlighted {
        background-color: #BFE4FF; // box-shadow: 0rem 0rem 0rem 0.13rem #31A5F7;
        //border: 0.13rem solid #31A5F7;
        border-radius: 0.2rem;
        width: 100%;
        padding: 0.5rem 0.4rem;
    }

    #SearchWrapper li:hover {
        background-color: #BFE4FF;
        border-radius: 0.2rem;
        box-shadow: none;
    }
}

.conditionTypeOperator {
    .reactSelectCSSMainDiv .css-2b097c-container {
        width: 7rem !important;
    }
}

.selectConditionType {
    .reactSelectCSSMainDiv .css-2b097c-container {
        width: 12rem !important;
    }
}

.noConfigLogs {
    color: #bfc2c7;
    font-size: 1.5rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}


.automationLogMainDiv{
    .reactSelectCSSMainDiv {
        margin-top: -5px;
        input {
            &:focus {
                box-shadow: none !important;
            }
        }

        .css-2b097c-container {
            width: 15rem;

            .css-1uccc91-singleValue {
                font-size: $font-input;
                color: $grey-0;
            }

            .css-yk16xz-control {
                border: $none;
                background: $none;
            }

            .css-1okebmr-indicatorSeparator {
                background: $none;
            }

            .css-1pahdxg-control {
                border: $none;
                box-shadow: $none;
                background: $none;

                :hover {
                    border: $none;
                    background: $none;
                }
            }

            .css-1gtu0rj-indicatorContainer {
                color: hsl(0, 0%, 80%)
            }
        }

        .css-26l3qy-menu {
            margin-top: 0px;

            .css-1ml51p6-MenuList {
                div {
                    font-size: $font-input;
                    color: $grey-1
                }

                .css-9gakcf-option {
                    background: white;

                    &:hover {
                        background: #DEEBFF;
                    }
                }
            }
        }

        .css-1wa3eu0-placeholder {
            font-size: $grey-0;
        }
    }
}

.triggertext {
    font-family: Roboto,"-apple-system", "BlinkMacSystemFont", "Segoe UI", Helvetica, Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",sans-serif;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.2px;
    color: #212121;
}
.automationImagePreview{
    padding-top : 26px;
    height: 490px;
}
.ModalList {
    padding-top: 12px;
    ul {
        padding: 0;
        // width: 60%;
        margin: 0 auto;
        li {
            list-style: none;
            label {
                cursor: pointer;
                font-family: Roboto,"-apple-system", "BlinkMacSystemFont", "Segoe UI", Helvetica, Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",sans-serif;
                font-size: 16px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: 0.16px;
                color: #5e6367;
                -webkit-touch-callout: none;
                /* iOS Safari */
                -webkit-user-select: none;
                /* Safari */
                -khtml-user-select: none;
                /* Konqueror HTML */
                -moz-user-select: none;
                /* Firefox */
                -ms-user-select: none;
                /* Internet Explorer/Edge */
                user-select: none;
                /* Non-prefixed version, currently */
                input[type=radio] {
                    margin: 0 0.7em 0 0;
                    height: auto
                }
            }
        }
        .disabled {
            opacity: 0.5;
            pointer-events: none;
        }
    }
}

.removeBlockPadding{
    margin-block-start: 0px;
    margin-block-end: 0px;
}

.filterBlock {
    margin: 0.4rem 1rem 0.3rem 0rem;
    display: inline-block;
    width: 100%;
    margin-left: 36px;

    .noFilterFound {
        font-size: 1rem;
        text-align: center;
        color: $grey-1;
        padding: 0 0 0.5rem 0;
    }

    .filterWrapper {
        position: relative;
        display: inline-block;
        vertical-align: middle;

        .filterDropdown {
            .filterText.heading {
                font-size: 14px;
                color: #ffffff;
                font-weight: 500;
                background-color: #1a73e8;
                /* padding-left: 0.5rem; */
                text-align: left;
                vertical-align: middle;
                font-style: Roboto;
                font-family: Roboto,"-apple-system", "BlinkMacSystemFont", "Segoe UI", Helvetica, Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",sans-serif;
                height: 38px;
                padding-top: 10px;
                // padding-bottom: 0.4em;
                padding-left : 15px
            }
        }

        .FilterBtnDD {
            float: left;
            // margin: 0 .5rem .2rem 0;
            position: relative;
        }

        .filterButton {
            float: left;
            // padding: 0 0.7rem 0 0.6rem;
            //padding: 0 0 0 .4rem;
            cursor: pointer;
            display: -ms-flexbox;
            display: flex;
            display: -webkit-flex;
            //background: $grey-6;
            //border: 1px solid $grey-7;
            //border-radius: .2rem;
            height: 16px;
            font-family: Roboto,"-apple-system", "BlinkMacSystemFont", "Segoe UI", Helvetica, Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",sans-serif;
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.14px;
            color: #727272;
            padding-left: 0px;

            &:hover {
                background: $white;
            }

            .filterArrow {
                background: url($REACT_APP_S3_URL+'filterDownIcon.svg') no-repeat left top;
                width: 24px;
                // height: 1.750rem;
                height: 24px;
                display: block;
                margin-top: -3px;
                object-fit: contain;

            }
        }

        .filterDropdown {
            background: $white;
            -webkit-box-shadow: 0rem 0.06rem 0.125rem 0.05rem $grey-3;
            box-shadow: 0rem 0.06rem 0.125rem 0.01rem #D4BDBD;
            position: absolute;
            top: -0.7rem;
            // left: -0.65rem;
            left: -15px;
            z-index: 20;
            //padding: 0 0.63rem 0rem 0.63rem;
            width: 250px;
            border-radius: 0rem;

            .heading {
                // border-bottom: 0.06rem $grey-3 solid;
                //margin-bottom: 0.5rem;

                a {
                    color: #ffffff;
                    font-size: $font-input;
                    text-decoration: none;
                    float: right;
                    cursor: pointer;
                    padding-right : .5rem
                }
            }

            .footer {
                // margin-bottom: 0.5rem;

                a {
                       font-size: 14px;
                        float: right;
                        cursor: pointer;
                        padding-right: 1.5rem;
                        color: #1a73e8;
                        padding-top: 9px;
                        padding-bottom: 0.5rem;
                        font-weight: 500;
                        height: 38px
                }
            }

            .filterSearchDiv {
                .searchFilter {
                    width: 100%;
                   // padding: 0.2rem;
                    margin-bottom: 0.5rem;
                    //border: 0.063rem solid #EBECED;
                    border-radius: -15rem 0.2rem 0.2rem 0.2rem;
                    /* background-color: #EBECED !important; */
                    background: url(https://trackhive-images.s3-ap-southeast-1.amazonaws.com/search.svg) no-repeat 14.2em 1rem;
                    font-size: 12px;
                    color: #434A54;
                    /* padding-left: 0.5rem; */
                    height: 40px;
                    //background-size: 20px;
                    &:focus {
                        border: 0.063rem solid #EBECED;
                        outline: none;
                    }
                }
            }

            .commonDropdownMenu {
                max-height: 270px;
                overflow-x: hidden;
                overflow-y: auto;

                ul {
                    list-style: none !important;
                    margin: 0 !important;
                   // padding: 0 !important;
                    padding-top : 9px;

                    li {
                        line-height: 1.50em;
                        margin: 0;
                        padding: 0.1rem 0 !important;
                        font-weight: 500;

                        label {

                            color: #434a54;
                            font-size: .75rem;
                            text-decoration: none;
                            cursor: pointer;
                            display: block;
                            width: 100%;
                            -o-text-overflow: ellipsis;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            overflow: hidden;
                            max-width: 195px;
                            padding-left: 2px;
                            line-height: 1rem;

                            input {
                                width: 13px !important;
                                margin: 0.5em;
                                float: left;
                            }

                            .spanText {
                                display: block;
                                float: left;
                                line-height: 1.6rem;
                                width: 80%;
                                white-space: nowrap;
                                overflow: hidden;
                                -o-text-overflow: ellipsis;
                                text-overflow: ellipsis;
                                text-align: left;
                            }
                        }
                    }
                }
            }
            .commonDropdownMenuStatic {
                max-height: 201px;
                overflow-x: hidden;
                overflow-y: auto;

                ul {
                    list-style: none !important;
                    margin: 0 !important;
                   // padding: 0 !important;
                    padding-top : 0px;

                    li {
                        line-height: 1.50em;
                        margin: 0;
                        padding: 0.1rem 0 !important;
                        font-weight: 500;

                        label {

                            color: #434a54;
                            font-size: .75rem;
                            text-decoration: none;
                            cursor: pointer;
                            display: block;
                            width: 100%;
                            -o-text-overflow: ellipsis;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            overflow: hidden;
                            max-width: 195px;
                            padding-left: 2px;
                            line-height: 1rem;

                            input {
                                width: 13px !important;
                                margin: 0.5em;
                                float: left;
                            }

                            .spanText {
                                display: block;
                                float: left;
                                line-height: 1.6rem;
                                width: 80%;
                                white-space: nowrap;
                                overflow: hidden;
                                -o-text-overflow: ellipsis;
                                text-overflow: ellipsis;
                                text-align: left;
                            }
                        }
                    }
                }
            }

            .transitDaysRange {
                min-height: 6.8rem;

                ul {
                    font-size: 0.75rem;
                    color: #434A54;
                    cursor: pointer;
                    font-weight: 400;
                    text-align: center;
                    text-decoration: none;
                    list-style: none;
                    margin: 0;
                    padding: 0;

                    li {
                        line-height: 1.50em;
                        list-style: none;
                        margin: 0;
                        padding: 0;

                        &:hover {
                            background-color: #BFE4FF !important;
                            border-radius: 4px;
                        }
                    }
                }
            }
            .custom-date-range-done {
                padding: 4px 2px;
                color: #fff;
                background-color: #31a5f7;
                text-align: center;
                border-radius: 4px;
                font-weight: 300;
                line-height: 1.5rem;
                cursor: pointer;
              }
        }
    }
    .actionIconWrapper{
        float: right;
        margin-right: 1.6rem;
        .actionIconImg{
            display: inline-grid;
            padding-left: 1.7rem;
            text-align: -webkit-center;
            cursor: pointer;
        }
        .actionIconText{
            height: 14px;
            font-family: Roboto,"-apple-system", "BlinkMacSystemFont", "Segoe UI", Helvetica, Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",sans-serif;
            font-size: 12px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.12px;
            color: #727272;
            padding-top: 5px;
        }
    }
    .selectedFiltersListDiv {
        display: inline;
        padding-left: 32px;
        vertical-align: top;
        //padding-top: 10PX;
        .Tags {
            font-size: 14px;
            padding: 0.4em 0.5em 0.4em 0.6em;
            display: inline-block;
            height: 32px;
            border-radius: 16px;
            background-color: #e0e0e0;
            margin: .4rem .5rem 0 0;
        }
        .tagText{
            height: 16px;
            font-family: Roboto,"-apple-system", "BlinkMacSystemFont", "Segoe UI", Helvetica, Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",sans-serif;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.14px;
            color: #212121;
        }

        .closeTag {
            background: url($REACT_APP_S3_URL+ 'cancel.svg') no-repeat left -0.15em;
            height: 1.375em;
            width: 1.5em;
            display: block;
            margin-left: 0.5em;
            float: right;
            cursor: pointer;
        }
    }
}
.selectedFiltersListDiv {
    margin-left: 36px;
}
.automationTags {
    font-size: 14px;
    padding: 0.4em 0.5em 0.4em 0.6em;
    display: inline-block;
    height: 32px;
    border-radius: 16px;
    background-color: #e0e0e0;
    margin: .4rem .5rem 0 0;
}
.automationtagText{
    height: 16px;
    font-family: Roboto,"-apple-system", "BlinkMacSystemFont", "Segoe UI", Helvetica, Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",sans-serif;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.14px;
    color: #212121;
}

.automationCloseTag {
    background: url($REACT_APP_S3_URL+ 'cancel.svg') no-repeat left -0.15em;
    height: 1.375em;
    width: 1.5em;
    display: block;
    margin-left: 0.5em;
    float: right;
    cursor: pointer;
}
.tagsText {
    height: 23px;
    font-family: Roboto,"-apple-system", "BlinkMacSystemFont", "Segoe UI", Helvetica, Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",sans-serif;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  color: #212121;
  padding-top: 30px;
    padding-bottom: 30px
}
.noAutomationDiv {
    .noAutomationInnerDiv {
        color: #BFC2C7;
        text-align: center;
        -ms-flex-item-align: center;
        align-self: center;
        max-width: 50rem !important;
        margin: 0 auto;
        position: absolute;
        left: 0px;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        padding: 0 3rem;
    }
    .noTrackingMsgTitle {
        font-size: 1.85rem;
        width: 17rem;
        margin: 0 auto;
        display: flow-root;
    }
}