@import '../../../static/css/common';

.subUserStyleMainDiv {
    .rTable {
        display: table;
        width: 95%;
        table-layout: fixed;
        font-size: 14px;
        border-spacing: 0.6rem;
    }
    .setting-box-content-con {
//        padding: 0 1.5rem;

        .add-new-link{
            display: inline-block;
            padding: .1rem, 0;
            line-height: 1rem;
            font-size: .875rem;
            cursor: pointer;
            margin-left: .685em;
            color: #7c828a !important;
        }

        .form-group {
            margin-bottom: 0.6rem;
            display: flow-root;
            clear: both;
        }
        .input-disable{
            cursor: no-drop;
        }
     
       
        .reactSwitchDiv {
            float: left;
            width: 100%;

            .switch.on .switch-toggle {
                left: 10px !important;
            }

            .switch-toggle-con {
                width: 30%;
                float: left;
                margin: 3px 0;
            }

            .switch {
                border: 1px solid #BFC2C7 !important;
                width: 26px !important;
                height: 16px !important;
                border-radius: 13px;
                cursor: pointer;
                display: inline-block;
                float: left;
                vertical-align: middle;
            }

            .switch.on {
                background: #66EB23 !important;
                border: 1px solid #66EB23 !important;
            }

            .switch-toggle {
                border: 1px solid #BFC2C7 !important;
                width: 14px !important;
                height: 14px !important;
                -webkit-box-shadow: none;
                box-shadow: none;
                left: 0;
                border-radius: 12px;
                background: #fff;
                position: relative;
                -webkit-transition: left .2s ease-in-out;
                -o-transition: left .2s ease-in-out;
                transition: left .2s ease-in-out;
            }

            .access-module-icon {
                float: left;
                width: 34px;
                height: 32px;
                display: block;
                margin: -5px 0.25rem 5px;
            }

            .access-switch-label {
                display: inline-block;
                vertical-align: middle;
                float: left;
                top: unset;
                position: unset;
                font-size: 14px;
                color: #434a54;
                letter-spacing: 0;
                font-weight: 500
            }
        }

        .access-switch-title {
            width: 100%;
            margin: 1.125em 0 0.75em 0.312em;
        }
       
        .font14-bold {
            font-size: 14px;
            color: #434A54;
            letter-spacing: 0;
            line-height: 20px;
            font-weight: 700;
        }


        .rTableRow {
            display: table-row;
        }

        .rTableCell {
            display: table-cell;
            padding: 3px, 10px;
        }

        .col-org-name {
            width: 30%;
            word-break: break-all;
            cursor: pointer;
        }

        .col-org-dpt {
            width: 24%;
            word-break: break-all;
            text-align: center;
        }

        .col-org-mail {
            width: 46%;
            word-break: break-all;
            text-align: right;
        }

        .resend {
            font-size: 12px;
            color: #7c828a;
            letter-spacing: .2px;
            line-height: 20px;
            text-decoration: underline;
            cursor: pointer;
        }

        .invite {
            font-size: 12px;
            color: #7c828a;
            letter-spacing: .2px;
            line-height: 20px;
        }

        .deactivated{
            color: #bfc2c7 !important;
        } 
        .resend-box {
            border: 1px solid #ebeced;
            border-radius: 4px;
            margin-bottom: 5px;
        }
        
        
    }
}