@import '../../../static/css/common';

.profileRightSideMainDiv {
    min-width: 32.725rem;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre-line;

    .profileSettingsContentContainer {
        padding: 0 1.5rem;

        @include commonFormStyle;

        .timeZoneStyle{

            input{
                width: 100% !important;
                font-size: 12px;
            }
            .css-1wa3eu0-placeholder{
                font-size: 12px;
            }

            .css-1pahdxg-control{
                border-color:hsl(0,0%,80%)  ;
                box-shadow: none ;
                &:hover, &:focus, &:active{
                    border-color:hsl(0,0%,80%)  ;
                    box-shadow: none ;
                }
            }
            .css-yk16xz-control, .css-11uccc91-singleValue,.css-1uccc91-singleValue{
                font-size: 12px !important;
            }
            .css-1ml51p6-MenuList{
                max-height: 150px;
                background: $white;
                div{
                    font-size: 14px !important;
                    cursor: pointer;
                }
            }
        }

    }


}
