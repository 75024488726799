@import '../../../static/css/common';

@mixin commonSettingsBox {
    .commonSettingsBox {
        background: $white;
        height: calc(100vh - 80px);
        margin-bottom: 10px;
        min-height: 100px;

        .commonSettingsBoxTitle {
            width: 100%;
            border-bottom: 1px solid $grey-3;
            margin: 0rem 0 1rem 0;
            height: 2.188rem;
            display: flow-root;
            background: #f7f7f7;

            .titleText {
                padding-left: 1.5rem;
                text-align: left;
                font-weight: $font-bold;
                font-size: 1.125rem;
                color: $grey-0;
                letter-spacing: 0;
                line-height: 35px;
                float: left;

                img {
                    width: 1.2rem;
                    float: left;
                    margin: 0.5rem 0.5rem 0 0;
                }
            }

            .setting-box-title-right {
                float: right;
                text-align: right;
                padding-right: 1rem;
                margin-top: 0.1rem;
                font-size: $font-input;
                color: $grey-0;
                cursor: pointer;
                line-height: 35px;

                .reactSwitchDiv {
                    display: inline-block;
                    text-align: right;
                    width: 29%;

                    .switch.on .switch-toggle {
                        left: 10px !important;
                    }

                    .switch-toggle-con {
                        float: right;
                        margin: 0.1rem 0rem 0 0;
                    }

                    .switch {
                        border: 1px solid #BFC2C7 !important;
                        width: 26px !important;
                        height: 16px !important;
                        border-radius: 13px;
                        cursor: pointer;
                        display: inline-block;
                    }

                    .switch.on {
                        background: #66EB23 !important;
                        border: 1px solid #66EB23 !important;
                    }

                    .switch-toggle {
                        border: 1px solid #BFC2C7 !important;
                        width: 14px !important;
                        height: 14px !important;
                        -webkit-box-shadow: none;
                        box-shadow: none;
                        left: 0;
                        border-radius: 12px;
                        background: #fff;
                        position: relative;
                        -webkit-transition: left .2s ease-in-out;
                        -o-transition: left .2s ease-in-out;
                        transition: left .2s ease-in-out;
                    }
                }
            }

            img.arrow {
                vertical-align: top;
                float: left;
                margin-top: 0.3rem;
            }

            .lightgrey a {
                text-align: left;
                font-weight: 700;
                font-size: 1.125rem;
                color: #c5c5c5;
                letter-spacing: 0;
                line-height: 35px;
                float: left;
                text-decoration: none;
            }

            .pageTitle {
                float: left;
                line-height: 2.3rem;
                color: $grey-0;
                font-weight: $font-bold;
            }

            .titleTextRightSideDiv {
                float: right;
                text-align: right;
                padding-right: 1rem;
                margin-top: 0.1rem;
                font-size: $font-input;
                color: $grey-0;
                cursor: pointer;
            }
        }

        .trackingPageListMainDiv {
            .eachTrackingListContainer {
                float: left;
                display: -ms-flexbox;
                display: flex;
                width: 100%;
                word-break: break-word;
                padding: 5px 0;

                .starDiv {
                    cursor: pointer;
                }

                .trackingPageName {
                    width: 30%;
                    font-size: 0.875rem;
                    line-height: 1.25rem;
                    color: #434A54;
                    font-weight: 400;
                    padding-right: 5px;
                    padding-left: 5px;
                }

                .actionButtonDiv {
                    width: 68%;
                    padding: 5px 0 5px 5px;

                    .actionBtn {
                        font-size: 0.875rem;
                        color: #7C828A;
                        width: 12%;
                        padding-right: 5px;
                        line-height: 1.25rem;
                        float: right;
                        text-align: right;
                        font-weight: 400;

                        span {
                            cursor: pointer;
                        }
                    }
                }
            }
        }


        .customTrackingPageMainWrapper {
            padding: 16px 16px 32px 32px;
            position: relative;
            height: 100%;

            .customTrackingPageInnerLeftSideDiv {
                float: left;
                width: 70%;
                margin-top: 18px;

                .MuiFormControl-root {
                    width: 100%;
                }

                .commonReactAceEditorMainDiv {
                    border: 1px solid rgba(0, 0, 0, 0.23);
                    margin-top: 32px;
                    margin-bottom: 2rem;
                    border-radius: 4px;

                    .ace_editor {
                        width: auto !important;
                        border: none !important;
                    }

                    .ace_print-margin {
                        visibility: hidden !important;
                    }
                }

            }



            .customTrackingPageInnerRightSideDiv {
                float: left;
                width: 25%;
                padding-left: 46px;
                margin-top: -20px;

                @media screen and (min-width: 481px) and (max-width: 1250px) {
                    width: 25%;
                    padding-left: 46px;
                    float: right;
                }

                .accordion {
                    border: none;
                    border-radius: unset;

                    .insert_value {
                        margin-bottom: 0.5rem;
                        font-size: 0.875rem;
                        font-weight: 700;


                    }
                }
            }

            @include commonFormStyle;
        }
    }
}

.settingsModule {
    /* Modal Starts */
    background-color: white;

    .integrationSwitch {
        position: absolute;
        right: 0px;
        top: 5px;
    }

    .dropdown-menu {
        right: -540%;
        width: 10.8rem;
        height: 9rem;
        border-radius: .5rem;
        transform: translateZ(0);
        position: absolute;
        will-change: unset;
        left: unset;
        top: 27px;
        display: none;
        z-index: 1;
    }

    /* Modal Ends */
    .dataContainer {
        .containerHeader {
            margin: 32px 0px 40px 32px;

            .containerTitle {
                width: 150px;
                height: 24px;
                font-size: 20px;
                font-stretch: normal;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                letter-spacing: 0.15px;
                color: #212121;
                display: inline-flex;
                position: relative;
                bottom: 14px;
            }
        }

        #settingDataScrollerWrapper {
            height: 100vh;

            .settingsContainer {
                height: calc(100% - 72px);
                display: -ms-flexbox;
                display: flex;

                .cardTitle {
                    font-weight: 600;
                    font-size: 20px;
                    color: #212121;
                    letter-spacing: 0.15px;
                }

                .cardSubTitle {
                    font-size: 14px;
                }

                .cardAction {
                    text-overflow: ellipsis;
                    overflow: hidden;
                    font-weight: 600;
                    text-decoration: none;
                    letter-spacing: 0.14px;
                    color: #1a73e8;
                }

                // settingsRightSidePanel starts
                .settingsRightSidePanel {
                    width: 100%;
                    min-width: 32.13rem;
                    max-height: 600px;
                    @include commonSettingsBox;

                    .customFormContainer {
                        margin-top: .5rem;

                        .input_wrap {
                            .form-attribute {
                                display: inline-block;
                                margin-bottom: 32px;
                                margin-left: 32px;
                            }
                        }
                    }
                }

                // settingsRightSidePanel close
            }

            .settingsRightSidePanel .dropdown-menu {
                .form-group {
                    padding: 0 15px;
                }
            }
        }

        .disbableFieldText {
            input {
                display: inline-block;
                color: #434A54;
                letter-spacing: 0.5px;
                line-height: 22px;
                padding: 19px 15px !important;
                font-family: inherit;
                font-size: 15px !important;
                width: 340px;
            }
        }
    }

    .upload-image-name {
        position: absolute;
        width: 300px;
        top: 4px;
        left: 122px;
        font-size: 0.75rem;
        color: #7C828A;
        letter-spacing: 0.22px;
        line-height: 28px;
    }
}