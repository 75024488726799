@import '../../static/css/common';

.dashboardModule {
  .dataContainer {
    padding: 0 0.5rem;
    width: 100%;
    padding-top: 7px;
    padding-left: 11px;
    padding-right: 17px;

    .commingSoonDiv {
      margin-top: 15%;
      font-size: 3rem;
      color: $grey-2;
    }

    .dataContentWrapper {
      margin-bottom: 16px;

      .cardNormal {
        margin: 0.55rem 0.8rem 0rem 0.2rem;
        cursor: default;
        float: none;
        height: auto;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        font-family: Roboto, "-apple-system", "BlinkMacSystemFont", "Segoe UI", Helvetica, Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
        border-radius: 4px;
        box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.2);
        background-color: #ffffff;

        .headingText {
          font-family: Roboto, "-apple-system", "BlinkMacSystemFont", "Segoe UI", Helvetica, Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
          font-size: 20px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.2px;
          color: #212121;
        }

        .InboxSummary {
          width: 100%;
          padding: 0 0.5rem; //display: flex; flex-basis: auto; flex-wrap: wrap; justify-content: space-evenly;

          .pieChartContainer {
            width: 45%;
            display: inline-block;
            vertical-align: middle;

            .highcharts-title {
              font-family: Roboto, "-apple-system", "BlinkMacSystemFont", "Segoe UI", Helvetica, Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
              font-weight: 500;
              font-style: normal;
            }

            .highcharts-subtitle {
              font-family: Roboto, "-apple-system", "BlinkMacSystemFont", "Segoe UI", Helvetica, Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
              font-weight: 500;
              font-style: normal;
            }
          }

          .pieChartSummaryContainer {
            width: 55%;
            display: inline-block;
            vertical-align: middle;
          }

          .summaryData {
            display: inline-block;
            width: 45%;
            padding: 0rem 0 1.5rem 0;
            margin: 0.5rem;
            color: $grey-0;
            border-bottom: 0.063rem solid $grey-3;
            cursor: pointer;

            &:last-child {
              border-bottom: 0 !important;
            }

            &:first-child {
              padding-top: 1.2rem !important;
            }

            .legendBullet {


              margin: auto 0;
              border-radius: 50%;
              display: inline-block;
              vertical-align: middle;
            }

            .statusName {
              display: inline-block;
              vertical-align: middle;
              margin-left: 0.5rem;

              .title {
                margin: auto 0;
                font-family: Roboto, "-apple-system", "BlinkMacSystemFont", "Segoe UI", Helvetica, Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
                font-size: 16px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: 0.14px;
                color: #212121;
              }

              .subtitle {
                font-family: Roboto, "-apple-system", "BlinkMacSystemFont", "Segoe UI", Helvetica, Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: 0.14px;
                color: #212121;
              }
            }

            .value {
              display: inline-block;
              vertical-align: middle;
              width: 30%;
              font-weight: $font-bold;
              text-align: right;
              margin: auto 0;
              font-size: 20px;
              direction: rtl;
              font-family: Roboto, "-apple-system", "BlinkMacSystemFont", "Segoe UI", Helvetica, Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
            }

            @media only screen and (max-width: 1201px) and (min-width: 1102px) {
              .statusName {
                .title {
                  font-size: 1rem !important;
                }
              }

              .value {
                font-size: 16px !important;
              }
            }

            @media only screen and (max-width: 1101px) and (min-width: 1002px) {
              .statusName {
                .title {
                  font-size: 0.9rem !important;
                }
              }

              .value {
                font-size: 14px !important;
              }
            }

          }
        }

        .topCourierListMainDiv {
          .topCourierInlineDiv {
            width: 98%;
            padding: 0.5rem;
            margin: 0 auto;
            border-bottom: 1px solid #ebeced;

            &:last-child {
              border-bottom: 0;
            }

            .courierNameLogo {
              display: inline-block;
              vertical-align: middle;
              width: 65%;
            }

            .courierLogo,
            .courierName {
              display: inline-block;
              vertical-align: middle;
              font-family: Roboto, "-apple-system", "BlinkMacSystemFont", "Segoe UI", Helvetica, Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
              font-size: 14px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: 0.14px;
              color: #212121;


              img {
                width: 30px;
                height: 30px;
              }

              span {
                color: #434A54;

                font-size: 1rem;
                font-weight: 500;
                margin-left: 0.5rem;
              }
            }

            .noOfCourier {
              vertical-align: middle;
              display: inline-block;
              text-align: right;
              width: 33%;
              font-family: Roboto, "-apple-system", "BlinkMacSystemFont", "Segoe UI", Helvetica, Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
              font-size: 14px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: 0.14px;
              color: #212121;
            }
          }
        }
      }

      .shipmentStatus {
        border-radius: 0.2rem 0.2rem 0 0;
        line-height: 1.40rem;
        font-size: 0.75rem;
        color: #434A54;
        background: #fff;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #ebeced;

        .icon {
          margin-bottom: 5px;
        }

        .MuiCardHeader-action {
          margin-top: 0px !important;
          margin-right: 0px !important;
        }

        .dateRange {

          display: flex;
          float: right;
          cursor: pointer;
          background: #ffffff;
          border: 1px solid #767676;
          border-radius: 4px;
          margin-left: 1rem;
          padding-top: 15px;
          padding-left: 20px;
          padding-right: 20px;
          padding-bottom: 15px;

          .Text {
            height: 18px;
            font-family: Roboto, "-apple-system", "BlinkMacSystemFont", "Segoe UI", Helvetica, Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
            font-size: 15px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.15px;
            color: #767676;
            text-align: center;
          }

          &:hover {
            background: $grey-3;
          }

          .ArrowDD {
            width: 1.5rem;
            display: block;
            background: url($REACT_APP_S3_URL +'downTriangle.svg') no-repeat center;
          }

        }
      }

    }
  }

  .commonDashboardSelectMainDiv {

    width: 16%;
    margin-right: 1rem;

    .css-2b097c-container {
      height: 30px;
    }

    .css-yk16xz-control,
    .css-1pahdxg-control {
      display: inline-block;
      width: 100%;
      min-height: 10px;
      flex-wrap: unset;
      height: 28px;
      border: 1px solid #dadde1;
      box-shadow: none;

      &:hover {
        box-shadow: none;
      }
    }

    .css-1gtu0rj-indicatorContainer {
      position: relative;
      top: -8px;
      color: hsla(0, 0%, 40%, 0.48);
    }

    .css-1ml51p6-MenuList {
      div {
        font-size: 0.75rem !important;
        color: #7C828A !important;

        &:hover {
          font-size: 0.75rem !important;
        }
      }

    }

    .css-1hwfws3 {
      display: inline-block;
      width: 74%;
      position: relative;
      top: -3px;
    }

    .css-tlfecz-indicatorContainer {
      position: relative;
      top: -8px;
    }

    .css-1g48xl4-IndicatorsContainer,
    .css-1wy0on6 {
      display: inline-block;
      top: -3px;
      position: relative;
      width: auto;
    }

    .css-1okebmr-indicatorSeparator {
      display: none;
    }
  }
}

.totalShipmentCount {
  position: absolute;
  left: 11.5rem;
  top: 6rem;
  font-size: 5rem;
}

@media only screen and (min-width: 1500px) and (max-width: 2100px) {
  .totalShipmentCount {
    left: 14rem;
  }
}