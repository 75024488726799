@import '../../../static/css/common';

$none : none !important;

.emailStyleMainDiv {
  min-width: 32.725rem;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre-line;

  .email_main_wrapper {
    .email_wrapper {
      display: flow-root;

      .email_insert_value {
        margin-top: -4.5px;
        width: 342px;
        float: left;
        margin-left: 46px;

        @media screen and (min-width: 481px) and (max-width: 1024px) {
          width: 342px;
          margin-top: 1.5rem;
          margin-left: 0px;
          float: left;
        }
      }

      .emailContentMainDiv {
        width: 712px;
        float: left;

        @media screen and (min-width: 481px) and (max-width: 1024px) {
          width: 712px;
          float: left;
        }
      }
    }
  }
}

@keyframes blinking {
  0% {
    background-color: #C9FDBB;
    border: 5px solid #C9FDBB;
  }

  50% {
    background-color: #C9FDBB;
    border: 5px solid #C9FDBB;
  }

  100% {
    background-color: #C9FDBB;
    border: 5px solid #C9FDBB;
  }
}