
.highcharts-button-symbol{
    height: 30px;
    width: 30px;
}
.highcharts-contextmenu {
   width: 250px;
   margin-right: 14px;
}

.highcharts-menu-item:hover {
    background-color: rgba(26, 115, 232, 0.12) !important;
    color: #212121 !important;
}

.highcharts-menu-item {
  padding: 10px !important;
  font-family: Roboto,"-apple-system", "BlinkMacSystemFont", "Segoe UI", Helvetica, Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",sans-serif;
  font-size: 14px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.14px;
  color: #212121 !important;
}

.highcharts-menu > hr {
  margin: 0px !important
}

