@import '../../../static/css/_common';

#onboarding_container {
    margin-top: -48px;

    input,
    button {

        &:focus,
        &:active {
            outline: none;
        }
    }

    .onboarding-container {
        padding: 2rem 4rem 0;
        max-width: 1020px;
        margin: 0px auto;
        height: calc(100vh - 81px);
        overflow: auto;
        overflow-x: hidden;

        .TitleBar {
            display: flow-root;

            &::before {
                clear: both;
                display: table;
            }

            .onboarding-title {
                font-size: 1.125rem;
                color: $grey-0;
                float: left;
                font-weight: 500;
            }
        }

        .onboarding-content {
            background: #ffffff;
            border: 1px solid $grey-3;
            border-radius: 4px;
            padding: 2rem;
            margin: 1rem 0;

            overflow: auto;
            overflow-x: hidden;
            overflow-y: auto;

            span {
                font-size: 16px;
                font-weight: 500;
            }

            .domainNameAvailableStyle {
                display: inline-block;
                width: 40%;
                margin-left: 1rem;
                padding-top: 13px;
            }

            .domainNameAvailableStyleYes {
                display: inline-block;
                width: 40%;
                margin-left: 1rem;
                padding-top: 18px;
            }

            img {
                vertical-align: middle;
                margin-right: 0.25rem;
            }

            p {
                font-size: 0.875rem;
                line-height: 1.375rem;
                color: $grey-0;
                margin: 0 0 1.5rem 0;
                font-weight: $font-regular;
            }
        }

        label {
            display: block;
            width: 100%;
            color: $grey-1;
            letter-spacing: 0.22px;
            line-height: 20px;
            margin-left: 5px;
        }

        .error-fonts {
            width: 100% !important;
            float: left;
            text-align: left;
            font-size: $font-label;
            color: $red-0;
            border-radius: $input-radius;
            border: 0;
        }

        #onboarding-submit {
            display: none;
        }
    }
}