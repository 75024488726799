@import '../../../static/css/common';

.webhookStyleMainDiv {
    width: 100%;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre-line;

    .webhookMainContainerDiv {
        //padding: 0 1.5rem;

        .webhookContentMainDiv {

            .webhookContentInnerDiv {
                display: table;
                width: 100%;
                border-bottom: 1px solid rgb(235, 236, 237);
                table-layout: fixed;
                font-size: 14px;
            }
        }
    }
}

@keyframes blinking {
    0% {
        background-color: #C9FDBB;
        border: 5px solid #C9FDBB;
    }

    50% {
        background-color: #C9FDBB;
        border: 5px solid #C9FDBB;
    }

    100% {
        background-color: #C9FDBB;
        border: 5px solid #C9FDBB;
    }
}

.test {
    animation: blinking 4s alternate;
}