@import '../../../static/css/common';

.tagStyleMainDiv {
    .rTable {
        display: table;
        width: 95%;
        table-layout: fixed;
        font-size: 14px;
        border-spacing: 0.6rem;
       
                  
    }
    .setting-box-content-con {    
        .btn-group {
            position: relative;
        }
    
        .floatLeft {
            float: left;
        }
        
        .show {
            display: block;
        }
    
        .tag-style {
            width: 1.25rem;
            height: 1.25rem;
            padding: 0;
            border-radius: 100%;
            display: block;
            float: left;
            margin-right: 10px;
            margin-top: 0;
            border: none;
            outline: none;
        }
        .tag-position {
            position: absolute;
            left: 0.5rem;
            width: 11.5rem;
            height: 9rem;
            background: #fff;
            -webkit-box-shadow: 0 0.125rem 0.625rem 0.313rem rgba(0,0,0,.05);
            box-shadow: 0 0.125rem 0.625rem 0.313rem rgba(0,0,0,.05);
            padding: .2rem;
            border-radius: .5rem;
        }
        .circle_picker {
            margin: .5rem 0 0 .5rem;
            width: 100%!important;
        }
        .BotBorder {
            border-bottom: .063em solid #ebeced;
        }
        .heupicker {
            width: 9.6rem!important;
            height: 1rem!important;
            margin-top: .75rem!important;
            margin-left: .6rem!important;
            margin-right: .75rem!important;
            border-radius: .75rem!important;
        }
        .dd-add-tag {
            color: #31a5f7!important;
            float: right!important;
            background: none;
            border: #ffdead;
            font-size: 14px;
            letter-spacing: .26px;
            cursor: pointer;
            margin-top: 1rem!important;
            outline: none;
        }
    }
}