@import '../../../static/css/_common';
$none : none !important;

@mixin commonHeadingStyle {
    .trackingHeading {
        font-size: 0.75rem;
        color: $grey-1;
        letter-spacing: 0.01rem;
        float: left;
        font-weight: $font-medium;

        .tracking_title {

            color: #434a54;
            letter-spacing: .01rem;
            line-height: 1rem;
            font-weight: 600;


        }

        &:first-child {
            width: 10%;
            text-align: left;

        }

        &:nth-child(2) {
            width: 20%;
            text-align: left;
        }

        &:nth-child(3) {
            width: 10%;
            text-align: left;
        }

        &:nth-child(4) {
            width: 10%;
            text-align: left;
        }

        &:nth-child(5) {
            width: 30%;
            text-align: left;
        }

        &:nth-child(6) {
            width: 10%;
            text-align: left;
        }

        &:nth-child(7) {
            width: 8%;
            text-align: center;
        }
    }
}

.trackingModule {
    width: 100%;
    float: left;
    margin-top: -48px;
    // margin-top : 2px;

    .dataContainer {

        // padding: 0 1rem;
        // max-width: 72.5rem;
        .filterBlock {
            margin: 0.5rem 1rem 0.5rem 0.4rem;
            display: inline-block;
            width: 100%;
            vertical-align: middle;


            .noFilterFound {
                font-size: 1rem;
                text-align: center;
                color: $grey-1;
                padding: 0 0 0.5rem 0;
            }

            .filterWrapper {
                position: relative;
                display: inline-block;
                padding-bottom: 0px;
                vertical-align: middle;

                .FilterBtnDD {
                    float: left;
                    position: relative;
                }


                .filterButton {
                    float: left;
                    cursor: pointer;
                    display: -ms-flexbox;
                    display: flex;
                    display: -webkit-flex;
                    height: 16px;
                    font-family: Roboto, "-apple-system", "BlinkMacSystemFont", "Segoe UI", Helvetica, Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
                    font-size: 14px;
                    font-weight: 500;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: 0.14px;
                    color: #727272;
                    padding-left: 32px;

                    // margin: 0 4px; 
                    &:first-child {
                        // padding-left: 1.5rem;
                    }

                    &:hover {
                        background: $white;
                    }

                    .filterArrow {
                        background: url($REACT_APP_S3_URL+'filterDownIcon.svg') no-repeat left top;
                        width: 24px;
                        // height: 1.750rem;
                        height: 24px;
                        display: block;
                        margin-top: -3px;
                        object-fit: contain;

                    }
                }

                .filterDropdown {
                    background: $white;
                    -webkit-box-shadow: 0rem 0.06rem 0.125rem 0.05rem $grey-3;
                    box-shadow: 0rem 0.06rem 0.125rem 0.01rem #D4BDBD;
                    position: absolute;
                    top: -0.7rem;
                    left: 18px;
                    z-index: 20;
                    width: 250px;
                    border-radius: 0rem;

                    .filterText.heading {
                        font-size: 14px;
                        color: #ffffff;
                        font-weight: 500;
                        background-color: #1a73e8;
                        text-align: left;
                        vertical-align: middle;
                        font-style: Roboto;
                        font-family: Roboto, "-apple-system", "BlinkMacSystemFont", "Segoe UI", Helvetica, Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
                        height: 38px;
                        padding-top: 10px;
                        padding-left: 15px
                    }

                    .heading {
                        a {
                            color: #ffffff;
                            font-size: $font-input;
                            text-decoration: none;
                            float: right;
                            cursor: pointer;
                            padding-right: .5rem
                        }
                    }

                    .footer {
                        div {
                            font-size: 14px;
                            float: right;
                            cursor: pointer;
                            padding-right: 1.5rem;
                            color: #1a73e8;
                            padding-top: 9px;
                            padding-bottom: 0.5rem;
                            font-weight: 500;
                            height: 38px
                        }
                    }

                    .filterSearchDiv {
                        .searchFilter {
                            width: 100%;
                            margin-bottom: 0.5rem;
                            border-radius: -15rem 0.2rem 0.2rem 0.2rem;
                            background: url(https://trackhive-images.s3-ap-southeast-1.amazonaws.com/search.svg) no-repeat 14.2em 1rem;
                            font-size: 12px;
                            color: #434A54;
                            height: 40px;
                            &:focus {
                                border: 0.063rem solid #EBECED;
                                outline: none;
                            }
                        }
                    }


                    .transitDaysRange {
                        min-height: 6.8rem;

                        ul {
                            font-size: 0.75rem;
                            color: #434A54;
                            cursor: pointer;
                            font-weight: 400;
                            text-align: center;
                            text-decoration: none;
                            list-style: none;
                            margin: 0;
                            padding: 0;

                            li {
                                line-height: 1.50em;
                                list-style: none;
                                margin: 0;
                                padding: 0;

                                &:hover {
                                    background-color: #BFE4FF !important;
                                    border-radius: 4px;
                                }
                            }
                        }
                    }
                }
            }

            .actionIconWrapper {
                display: inline-block;
                float: right;
                margin-right: 1.6rem;
                vertical-align: middle;
                line-height: 1;

                .actionIconImg {
                    display: inline-grid;
                    padding-left: 1.7rem;
                    text-align: -webkit-center;
                    cursor: pointer;
                    vertical-align: middle;

                    img {
                        vertical-align: middle;
                    }
                }

                .actionIconText {
                    height: 14px;
                    font-family: Roboto, "-apple-system", "BlinkMacSystemFont", "Segoe UI", Helvetica, Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
                    font-size: 12px;
                    font-weight: 500;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: 0.12px;
                    color: #727272;
                }
            }

            .selectedFiltersListDiv {
                display: inline;
                padding-left: 32px;
                vertical-align: middle;
                line-height: 1;

                //padding-top: 10PX;
                .Tags {
                    font-size: 14px;
                    padding: 0.6em 0.5em 0em 0.6em;
                    display: inline-block;
                    margin-right: .4em;
                    height: 32px;
                    border-radius: 16px;
                    background-color: #e0e0e0;
                }

                .tagText {
                    height: 16px;
                    font-family: Roboto, "-apple-system", "BlinkMacSystemFont", "Segoe UI", Helvetica, Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: 0.14px;
                    color: #212121;
                }

                .closeTag {
                    background: url($REACT_APP_S3_URL+ 'cancel.svg') no-repeat left -4px;
                    height: 1.375em;
                    width: 1.5em;
                    display: block;
                    margin-left: 0.5em;
                    float: right;
                    cursor: pointer;
                }
            }
        }

        .trackingTabViewMainDiv {
            display: flow-root;
            padding-bottom: 1px;

            .tab {
                height: 48px;
                box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
                background-color: #ffffff;

                .fullscreen {
                    display: block;
                    float: right;
                    font-size: 0.75rem;
                    margin-top: 0.7rem;
                    cursor: pointer;
                    color: $grey-1;
                    text-decoration: none;
                }

                button {
                    background-color: $white;
                    float: left;
                    border: 0px solid #dee0e1;
                    border-radius: 4px 4px 0 0;
                    outline: none;
                    cursor: pointer;
                    padding: 0.8rem 0.9rem 1.8rem 1.0rem;
                    transition: 0.3s;
                    text-align: center;
                    margin: 3px 5px 0 0;
                    height: 45px;
                    font-family: Roboto, "-apple-system", "BlinkMacSystemFont", "Segoe UI", Helvetica, Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
                    font-size: 14px;
                    font-weight: 500;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: 0.14px;
                    color: #727272;

                    &:hover {
                        color: #1a73e8;
                    }

                    &.ButtonActive {
                        color: #1a73e8;
                        border-bottom: 2px solid #1a73e8;
                        box-shadow: none !important;
                    }
                }

            }

        }


        .dataScrollerWrapper {
            height: auto;

            .trackingListTabularStyleMainDiv {

                .trackingListHeaderMainDiv {
                    display: flow-root;
                    padding: 0.4rem 0;
                    background: #f6f7f8;
                    border: 1px solid #EBECED;
                    border-top: none !important;

                    .trackingListHeaderLabel {
                        font-size: 0.75rem;
                        color: #7C828A;
                        letter-spacing: 0.01rem;
                        float: left;
                        font-weight: 500;
                        text-align: center;

                        .trackingLabelTitle {
                            color: rgb(67, 74, 84);
                            letter-spacing: 0.01rem;
                            line-height: 1rem;
                            font-weight: 600;
                        }

                        &:first-child {
                            width: 5%;

                        }

                        &:nth-child(2) {
                            width: 13%;
                            padding: 0 1%;
                        }

                        &:nth-child(3) {
                            width: 13%;
                            text-align: left;
                        }

                        &:nth-child(4) {
                            width: 12%;
                            padding: 0 1%;
                        }

                        &:nth-child(5) {
                            width: 12%;
                            padding: 0 1%;
                        }

                        &:nth-child(6) {
                            width: 19%;
                            text-align: left;
                        }

                        &:nth-child(7) {
                            width: 8%;
                            text-align: center;
                        }

                        &:nth-child(8) {
                            width: 6%;
                            text-align: center;
                        }

                        &:nth-child(9) {
                            width: 6%;
                            text-align: center;
                        }

                    }
                }

                .trackingCardMainDiv {
                    .trackingCard {
                        background: $white;
                        display: flow-root;
                        padding: 0.75rem 0;
                        cursor: pointer;
                        border-bottom: 1px solid #e8e9ea;
                        border-radius: .0rem;
                        width: 100%;

                        #tagScroll {
                            margin: 0.2rem 0.2rem 0.2rem 1.2rem;

                            .tag {
                                color: #fff;
                                font-size: .70rem;
                                line-height: 1.3rem;
                                padding: 0 .5rem;
                                border-radius: .2rem;
                                margin-right: .3125rem;
                                display: inline-block;
                            }
                        }

                        &:hover {
                            background: $cardHover;
                            border-bottom: 1px solid #e8e6c4;
                        }

                        .trackingCardHeadingLabel {
                            font-size: 0.75rem;
                            color: #7C828A;
                            letter-spacing: 0.01rem;
                            display: inline-block;
                            font-weight: 500;
                            text-align: center;
                            vertical-align: middle;

                            &:first-child {
                                width: 5%;

                            }

                            &:nth-child(2) {
                                width: 13%;
                                padding: 0 1%;
                            }

                            &:nth-child(3) {
                                width: 13%;
                                text-align: left;
                            }

                            &:nth-child(4) {
                                width: 12%;
                                padding: 0 1%;
                            }

                            &:nth-child(5) {
                                width: 12%;
                                padding: 0 1%;
                            }

                            &:nth-child(6) {
                                width: 19%;
                                text-align: left;
                            }

                            &:nth-child(7) {
                                width: 8%;
                                text-align: center;
                            }

                            &:nth-child(8) {
                                width: 6%;
                                text-align: center;
                            }

                            &:nth-child(9) {
                                width: 6%;
                                text-align: center;
                            }

                            .labelInfo {
                                color: $grey-0;
                                font-size: $font-input;
                                max-width: 100%;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: block;

                                img {
                                    width: 30px;
                                    height: 30px;
                                }

                            }
                        }
                    }
                }
            }
        }

        .trackingDetailsWrapper {
            height: 100%;
            .trackingDetailsFooterMainDiv {
                bottom: 0;
                position: fixed;
                padding: 0.65rem 0 0 0;
                height: 3.075rem;
                background: #506898;
                min-width: 960px;
                text-align: center;

                .centerItems {
                    display: inline-block;
                }

                .commonFooter {
                    background: transparent;
                }

                .bottomPrimaryButton {
                    background-color: #fff;
                    width: 8rem;
                    height: 2.4rem;
                    text-decoration: none;
                    display: flow-root;
                    text-align: center;
                    float: left;
                    margin-right: .5rem;
                    cursor: pointer;
                    outline: none;
                    border-radius: .25rem;
                    box-shadow: 0 0px 4px 0px #323c51;


                }

                span.trackingList_edit,
                span.trackingList_delete {
                    color: #434a54;
                    font-size: .875rem;
                    display: inline-block;
                    line-height: 2.3rem;

                    img {
                        margin: .18rem .625rem 0 0;
                        float: left;
                        height: 32px;
                    }

                    span.buttonText {
                        font-size: 0.875rem;
                        padding-left: 10px;
                        color: #495059;
                        line-height: 39px;
                    }
                }

            }

            .trackingDetailsWrapperContainer {
                background: #f5f5f5;
                -webkit-box-shadow: 0 0.063rem 0.125rem 0.063rem rgba(0, 0, 0, 0.05), 0 0.313rem 1.875rem 0.313rem rgba(0, 0, 0, 0.1);
                box-shadow: 0 0.063rem 0.125rem 0.063rem rgba(0, 0, 0, 0.05), 0 0.313rem 1.875rem 0.313rem rgba(0, 0, 0, 0.1);
                position: relative;
                border-radius: 0;
                margin-bottom: 0.8rem;

                .tag-position {
                    position: absolute;
                    margin-top: -12rem;
                    left: 0.5rem;
                    width: 11rem;
                    height: 10.2rem;
                    background: $white;
                    box-shadow: 0 0.125rem 0.625rem 0.313rem rgba(0, 0, 0, 0.05);
                    padding: 0.2rem;
                    border-radius: 0.5rem;

                    .title {
                        font-size: 0.875rem;
                        font-weight: $font-bold;
                        color: $grey-0;
                        text-align: center;
                        border-bottom: 0.063em solid $grey-3;
                        padding: 0;
                        line-height: 1.5rem;
                        float: left;
                        width: 100%;
                        margin-top: 0.1rem;
                        font-family: $font;
                    }
                }

                .circle_picker {
                    margin: 2rem 0 0 0.5rem;
                    width: 170px !important;
                }

                .dd-add-tag {
                    background: none;
                    border: navajowhite;
                    font-size: 14px;
                    letter-spacing: 0.26px;
                    cursor: pointer;
                    color: #31A5F7 !important;
                    float: right !important;
                    margin-top: 1rem;
                    outline: none;
                }

                .dd-cancel-tag {
                    background: none;
                    border: navajowhite;
                    font-size: 14px;
                    letter-spacing: 0.26px;
                    cursor: pointer;
                    color: $grey-2 !important;
                    float: left !important;
                    margin-top: 1rem;
                    outline: none;
                }

                .huepicker {
                    width: 9.60rem !important;
                    height: 1.0rem !important;
                    margin-top: 0.75rem !important;
                    margin-left: 0.6rem !important;
                    margin-right: 0.75rem !important;
                    border-radius: 0.75rem !important;
                }

                .tag {
                    color: #FFFFFF;
                    font-size: 0.65rem;
                    line-height: 1.3rem;
                    padding: 0rem 0.5rem;
                    border-radius: 0.2rem;
                    margin-right: 0.3125rem;
                    display: inline-block;

                    .closeTag {
                        -webkit-mask: url(../../../../src/Setup/assets/img/close.svg) no-repeat left top;
                        -webkit-mask-image: url(../../../../src/Setup/assets/img/close.svg) no-repeat left top;
                        -webkit-mask-size: cover;
                        mask: url(../../../../src/Setup/assets/img/close.svg) no-repeat left top;
                        mask-image: url(../../../../src/Setup/assets/img/close.svg) no-repeat left top;
                        mask-size: cover;
                        background-color: #ffffff;
                        height: 0.6rem;
                        width: 0.6rem;
                        margin-left: 0.5rem;
                        cursor: pointer;
                        display: inline-block;
                    }
                }

                .trackingDetailHeaderDiv {
                    background: #eff0f1;
                    border-bottom: 1px solid #dadde1;
                    padding: 0.46rem 0.5rem 0.46rem 1.5rem;
                    position: relative;
                    z-index: 999;

                    .trackingNumberTitle {
                        color: $grey-0;
                        font-weight: $font-bold;
                        font-size: 1rem;
                        display: inline-block;
                        width: 80%;
                        vertical-align: middle;
                        height: auto;

                        .trackingURLDiv {
                            a {
                                text-decoration: none;
                                font-size: 0.83rem;
                                font-weight: 500;
                                margin-top: 0.15rem;
                                color: $blue-1;
                                vertical-align: middle;
                            }

                            img {
                                vertical-align: middle;
                                cursor: pointer;
                            }
                        }
                    }

                    .closeDetailDiv {
                        display: inline-block;
                        width: 19%;
                        text-align: right;
                        vertical-align: middle;
                        cursor: pointer;
                    }
                }

                .dateMainDiv {

                    .dateInnerDiv {
                        display: inline-block;
                        vertical-align: top;
                        width: 100%;

                        .dateTitle {

                            margin-top: 0rem;
                            margin-bottom: 5px;
                            color: $grey-1;
                            font-weight: $font-medium;
                            font-size: $font-label;
                        }

                        .dateValue {
                            margin-bottom: 0.85rem;
                            color: $grey-0;
                            font-weight: $font-bold;
                            font-size: $font-label;
                        }
                    }
                }

                hr {
                    height: 0px;
                    background: $grey-1;
                    border: none;
                    margin: 0rem 0;
                }

                .trackingDetailsBodyDiv {
                    font-size: 0;
                    display: flex;
                    min-height: calc(100% - 12px);
                    z-index: 0;
                    position: relative;


                    .trackingHistoryTimelineMainDiv {
                        display: inline-block;
                        vertical-align: top;
                        padding-left: 1.5rem;
                        flex: 2;
                        position: relative;
                        z-index: 9;
                        background: #fff;
                        border-right: 1px solid #dadde1;



                        .title {
                            color: $grey-0;
                            font-size: 1rem;
                            font-weight: $font-bold;
                            margin: 1rem 0;
                        }

                        .trackingHistoryTimelineInnerDiv {
                            margin-left: -1rem;

                            table {
                                border-spacing: 0
                            }

                            td {
                                padding: 16px 0;
                                border: none;
                                text-align: center;
                                vertical-align: top;
                            }

                            td.trackingTimelineDateTimeValue {
                                color: #555;
                                font-size: 14px;
                                width: 100px;
                                vertical-align: top;
                                text-align: right;

                                p {
                                    margin: 0;
                                    color: $grey-0;
                                    font-weight: $font-bold
                                }

                                p.time {
                                    color: $grey-1;
                                    font-weight: $font-medium;
                                    font-size: $font-label;
                                }
                            }

                            .trackingTimelineStatusIcon {
                                width: 50px;
                                vertical-align: top;
                                background-image: url($REACT_APP_S3_URL+ 'status_new.png');
                                background-repeat: no-repeat;
                                background-position: -125px;

                                &:last-child {
                                    background-position: 160px 0;
                                }

                                div {
                                    height: 30px;
                                    width: 30px;
                                    background-image: url($REACT_APP_S3_URL+'track_status_icon.png');
                                    margin-left: auto;
                                    margin-right: auto;
                                    background-size: contain;
                                }

                                div.InTransit {
                                    background-position: 60px 0px;
                                    background-image: url($REACT_APP_S3_URL+'InTransit.svg');
                                }

                                div.Delivered {
                                    background-position: 30px 0px;
                                    background-image: url($REACT_APP_S3_URL+'Delivered.svg');
                                }

                                div.Pending {
                                    background-position: 120px 0px;
                                    background-image: url($REACT_APP_S3_URL+'Pending.svg');
                                }

                                div.OutForDelivery {
                                    background-position: 150px 0px;
                                    background-image: url($REACT_APP_S3_URL+'OutForDelivery.svg');
                                }

                                div.InfoReceived {
                                    background-position: 150px 0px;
                                    position: relative;
                                    top: -5px;
                                    background-image: url($REACT_APP_S3_URL+'InfoReceived.svg');
                                }

                                div.FailedAttempt {
                                    background-position: 150px 0px;
                                    background-image: url($REACT_APP_S3_URL+'FailedAttempt.svg');
                                }

                                div.Expired {
                                    background-position: 150px 0px;
                                    background-image: url($REACT_APP_S3_URL+'Expired.svg');
                                }

                                div.Exception {
                                    background-position: 150px 0px;
                                    background-image: url($REACT_APP_S3_URL+'Exception.svg');
                                }
                            }

                            td.trackingTimelineInfo {
                                color: $grey-0;
                                font-size: $font-input;
                                text-align: left;

                                .itemLocation {
                                    font-weight: $font-bold;
                                }

                                .trackingMessag {
                                    font-weight: $font-regular;
                                }
                            }
                        }
                    }
                }
            }

        }

        .noTrackingDiv {
            .noTrackingInnerDiv {
                color: #BFC2C7;
                text-align: center;
                -ms-flex-item-align: center;
                align-self: center;
                max-width: 50rem !important;
                margin: 0 auto;
                position: absolute;
                left: 0px;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                padding: 0 3rem;

                .noTrackingMsgTitle {
                    font-size: 1.85rem;
                    width: 17rem;
                    margin: 0 auto;
                    display: flow-root;
                }
            }
        }
    }
}

.searchTrackingMainDiv {


    #SearchWrapper {
        position: relative;
        float: right;
        margin: 0 10rem 0rem 1rem;
        width: auto;
        height: 1.75rem;
        display: block;
        right: 20px;

        input.trackingSearchInput::placeholder {
            color: #767b82 !important;
        }

        .imageSearchClearContainer {

            width: 25px;
            height: 25px;
            right: -77px;
            position: absolute;

            .imageSearchClear {
                position: absolute !important;
                right: 0px;
                top: 2px;
                cursor: pointer;
                z-index: 98;
            }
        }

        .react-autosuggest__container {
            float: left;
            border: none;
            padding: 0;
            line-height: 0;
            z-index: 10;
            width: 100% !important;
            position: relative;
            margin: unset;
            right: -40px;
        }

        .trackingSearchInput {
            width: 22rem;
            height: 100%;
            background: #ffffff url($REACT_APP_S3_URL+'searchBarIcon.svg') no-repeat 14px 2px;
            border: 0;
            border-width: 0 0 1px 0;
            padding: 0.4rem 0.5rem 0.4rem 2.7rem;
            outline: 0;
            font-size: 0.875rem;
            color: #434A54;
            border-radius: 4px;
        }

        .react-autosuggest__suggestions-container--open {
            display: block;
            position: absolute;
            top: auto;
            bottom: 100%;
            width: 7rem !important;
            padding: 0.3rem !important;
            -webkit-box-shadow: 0 2px 10px 5px rgba(0, 0, 0, 0.05);
            box-shadow: 0 2px 10px 5px rgba(0, 0, 0, 0.05);
            border-radius: 4px;
            background-color: #fff;
            z-index: 2;
            color: #434A54 !important;
        }

        .react-autosuggest__suggestions-container {
            width: 100% !important;
            padding: 0em;
            margin: 0em;
            position: relative;
            overflow: auto;
            background: #FFFFFF;
            z-index: 1000;
            top: 0.2rem;

            .react-autosuggest__suggestions-list {
                margin: 0;
                padding: 0 0.15rem;
                list-style-type: none;
                font-size: 0.875em;
                padding-top: 0.3rem;
                width: 98%;

                .react-autosuggest__suggestion {
                    cursor: pointer;
                    color: #434A54;
                    line-height: 1em;
                    list-style: none;
                    display: -ms-flexbox;
                    display: flex;
                    -ms-flex-item-align: center;
                    align-self: center;
                    -ms-flex-align: center;
                    align-items: center;
                    margin-bottom: 0.4rem;
                    padding: 0.5rem 0.4rem;
                    width: 100%;

                    .trackingNumber {
                        color: #434A54;
                        display: block;
                        padding-bottom: 0.3em;
                        font-weight: 500;
                        font-size: 0.95rem;
                    }

                    .courierName {
                        font-weight: 400;
                        font-size: 0.75rem;
                        color: #7C828A;
                        text-align: left;
                        line-height: 1rem;
                        cursor: pointer;
                        display: block;
                        text-transform: uppercase;
                    }
                }
            }
        }

        li {
            &:hover {
                background-color: #BFE4FF;
                border-radius: 0.4em;
                -webkit-box-shadow: none;
                box-shadow: none;
            }
        }

        .react-autosuggest__suggestions-container--no {
            display: block;
            position: absolute;
            width: 100% !important;
            left: 0px;
            top: 2rem;
            -webkit-box-shadow: 0 2px 10px 5px rgba(0, 0, 0, 0.05);
            box-shadow: 0 2px 10px 5px rgba(0, 0, 0, 0.05);
            border-radius: 4px;
            z-index: 2;
            color: #434A54 !important;
            font-size: 0.875rem;
            text-align: center;
            line-height: 1rem;
            font-weight: 400;
            background: white;
            padding: 0.875rem 0;
            font-family: Roboto, "-apple-system", "BlinkMacSystemFont", "Segoe UI", Helvetica, Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
        }
    }
}

.filterWrapperRight {
    position: relative;
    display: inline-block;

    .FilterBtnDD {
        float: left;
        position: relative;
    }

    .filterButton {
        float: left;
        cursor: pointer;
        display: -ms-flexbox;
        display: flex;
        display: -webkit-flex;
        height: 16px;
        font-family: Roboto, "-apple-system", "BlinkMacSystemFont", "Segoe UI", Helvetica, Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.14px;
        color: #727272;
        padding-left: 32px;

        // margin: 0 4px; 
        &:first-child {
            // padding-left: 1.5rem;
        }

        &:hover {
            background: $white;
        }

        .filterArrow {
            background: url($REACT_APP_S3_URL+'filterDownIcon.svg') no-repeat left top;
            width: 24px;
            // height: 1.750rem;
            height: 24px;
            display: block;
            margin-top: -3px;
            object-fit: contain;

        }
    }

    .filterDropdown {
        background: $white;
        -webkit-box-shadow: 0rem 0.06rem 0.125rem 0.05rem $grey-3;
        box-shadow: 0rem 0.06rem 0.125rem 0.01rem #D4BDBD;
        position: absolute;
        top: -2.3rem;
        left: -200px;
        z-index: 20;
        width: 250px;
        border-radius: 0rem;

        .filterText.heading {
            font-size: 14px;
            color: #ffffff;
            font-weight: 500;
            background-color: #1a73e8;
            text-align: left;
            vertical-align: middle;
            font-style: Roboto;
            font-family: Roboto, "-apple-system", "BlinkMacSystemFont", "Segoe UI", Helvetica, Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
            height: 38px;
            padding-top: 10px;
            padding-left: 15px
        }

        .heading {
            a {
                color: #ffffff;
                font-size: $font-input;
                text-decoration: none;
                float: right;
                cursor: pointer;
                padding-right: .5rem
            }
        }

        .footer {
            a {
                font-size: 14px;
                float: right;
                cursor: pointer;
                padding-right: 1.5rem;
                color: #1a73e8;
                padding-top: 12px;
                padding-bottom: 0.5rem;
                font-weight: 500;
                height: 38px
            }
        }

        .filterSearchDiv {
            .searchFilter {
                width: 100%;
                margin-bottom: 0.5rem;
                border-radius: -15rem 0.2rem 0.2rem 0.2rem;
                background: url(https://trackhive-images.s3-ap-southeast-1.amazonaws.com/search.svg) no-repeat 14.2em 1rem;
                font-size: 12px;
                color: #434A54;
                height: 40px;

                &:focus {
                    border: 0.063rem solid #EBECED;
                    outline: none;
                }
            }
        }

        .commonDropdownMenu {
            max-height: 270px;
            overflow-x: hidden;
            overflow-y: auto;

            ul {
                list-style: none !important;
                margin: 0 !important;
                padding-top: 9px;

                li {
                    line-height: 1.50em;
                    margin: 0;
                    padding: 0.1rem 0 !important;
                    font-weight: 500;

                    label {

                        color: #434a54;
                        font-size: .75rem;
                        text-decoration: none;
                        cursor: pointer;
                        display: block;
                        width: 100%;
                        -o-text-overflow: ellipsis;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                        max-width: 195px;
                        padding-left: 2px;
                        line-height: 1rem;

                        input {
                            width: 13px !important;
                            margin: 0.5em;
                            float: left;
                        }

                        .spanText {
                            display: block;
                            float: left;
                            line-height: 1.6rem;
                            width: 80%;
                            white-space: nowrap;
                            overflow: hidden;
                            -o-text-overflow: ellipsis;
                            text-overflow: ellipsis;
                            text-align: left;
                        }
                    }
                }
            }
        }

        .commonDropdownMenuStatic {
            max-height: 201px;
            overflow-x: hidden;
            overflow-y: auto;

            ul {
                list-style: none !important;
                margin: 0 !important;
                padding-top: 0px;

                li {
                    line-height: 1.50em;
                    margin: 0;
                    padding: 0.1rem 0 !important;
                    font-weight: 500;

                    label {
                        color: #434a54;
                        font-size: .75rem;
                        text-decoration: none;
                        cursor: pointer;
                        display: block;
                        width: 100%;
                        -o-text-overflow: ellipsis;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                        max-width: 195px;
                        padding-left: 2px;
                        line-height: 1rem;

                        input {
                            width: 13px !important;
                            margin: 0.5em;
                            float: left;
                        }

                        .spanText {
                            display: block;
                            float: left;
                            line-height: 1.6rem;
                            width: 80%;
                            white-space: nowrap;
                            overflow: hidden;
                            -o-text-overflow: ellipsis;
                            text-overflow: ellipsis;
                            text-align: left;
                        }
                    }
                }
            }
        }

        .transitDaysRange {
            min-height: 6.8rem;

            ul {
                font-size: 0.75rem;
                color: #434A54;
                cursor: pointer;
                font-weight: 400;
                text-align: center;
                text-decoration: none;
                list-style: none;
                margin: 0;
                padding: 0;

                li {
                    line-height: 1.50em;
                    list-style: none;
                    margin: 0;
                    padding: 0;

                    &:hover {
                        background-color: #BFE4FF !important;
                        border-radius: 4px;
                    }
                }
            }
        }
    }
}

.css-yt9ioa-option {
    font-size: 0.875rem !important;
    background: #FFFFFF;
    color: #7C828A !important;

    &:hover {
        font-size: 0.875rem !important;
        background: #DEEBFF !important;
    }
}

.css-9gakcf-option {
    font-size: 0.875rem !important;
    background: #ffffff !important;
    color: #7C828A !important;

    &:hover {
        font-size: 0.875rem !important;
        background: rgb(222, 235, 255) !important;
    }
}

.css-1n7v3ny-option {
    font-size: 0.875rem !important;
    background: #FFFFFF;
    color: #7C828A !important;

    &:hover {
        font-size: 0.875rem !important;
    }
}

.exportMessage {

    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    z-index: 10001;
    bottom: 5rem;
    font-size: 0.875rem;
    width: auto;
    background: rgba(0, 0, 0, 0.65);
    color: white;
    padding: 0.25rem;
    border-radius: 0.25rem;

    img {
        vertical-align: middle;
    }
}

.hrMarginBlock {
    margin-block-end: 0px;
    margin-block-start: 0px;
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    padding: 0;
}

.addIcon {
    height: 40px;
    width: 40px;
}

.filterIcon {
    padding-left: 2rem;
    display: inline-block;
    cursor: pointer;
    vertical-align: middle;

    img {
        display: block;
    }
}

.addTrackingIcon {
    display: inline-block;
    cursor: pointer;
    vertical-align: middle;

    img {
        vertical-align: middle;
    }
}

.filterDropdownCustom {
    background: #fff;
    box-shadow: 0 0.06rem 0.125rem 0.05rem #ebeced;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 20;
    padding: 0 .63rem;
    min-width: 12.5rem;
    border-radius: .2rem;
}

.activeColor {
    color: #1a73e8;
}

// .drawerInputWwrap{
.formAttribute {
    display: inline-block;
    margin-bottom: 40px;
    margin-left: 32px;
}

.editformAttribute {
    display: inline-block;
    margin-bottom: 32px;
    margin-left: 32px;
}

.error-fonts {
    font-size: 12px !important;
    color: #f44336 !important;
    letter-spacing: 0;
    line-height: 16px;
    font-weight: 500;
    margin-left: 6px;
    font-family: Roboto, "-apple-system", "BlinkMacSystemFont", "Segoe UI", Helvetica, Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
    margin-top: 5px;
    display: table;
}

.formAttributeTop {
    display: inline-block;
    margin-bottom: 32px;
    margin-left: 32px;
}

.editformAttributeTop {
    display: inline-block;
    margin-bottom: 32px;
    margin-left: 32px;
}

.editformAttributeDate {
    display: inline-block;
    margin-left: 32px;
    width: 282px;
    padding-bottom: 50px;
}

.editDateText {
    font-family: Roboto, "-apple-system", "BlinkMacSystemFont", "Segoe UI", Helvetica, Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.14px;
    color: #666666;
}

.editDateValue {
    font-family: Roboto, "-apple-system", "BlinkMacSystemFont", "Segoe UI", Helvetica, Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.14px;
    color: #212121;
    padding-top: 6px
}

.trackingHistoryTimelineInnerDiv {
    margin-left: -1rem;

    table {
        border-spacing: 0
    }

    tr {
        &td:last-child {
            background-position: 160px 0px;
        }
    }

    td {
        padding: 16px 0;
        border: none;
        text-align: center;
        vertical-align: top;

        &:first-child {
            background: linear-gradient(90deg, rgba(0, 0, 0, 0) calc(50% - 1px), rgb(189, 189, 189) calc(50%), rgba(0, 0, 0, 0) calc(50% + 1px));
            padding: 0px 0px 16px 0px;
            background-repeat: no-repeat;
        }
    }

    td.trackingTimelineDateTimeValue {
        vertical-align: top;
        text-align: right;
        height: 16px;
        font-family: Roboto, "-apple-system", "BlinkMacSystemFont", "Segoe UI", Helvetica, Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.14px;
        color: #212121;
        padding-left: 81px;

        p {
            margin: 0;
        }
    }

    .trackingTimelineStatusIcon {
        width: 50px;
        vertical-align: top;

        div.InTransit {
            background-image: url($REACT_APP_S3_URL+'InTransit.svg');
        }

        div.Delivered {
            background-image: url($REACT_APP_S3_URL+'Delivered.svg');
        }

        div.Pending {
            background-image: url($REACT_APP_S3_URL+'Pending.svg');
        }

        div.OutForDelivery {
            background-image: url($REACT_APP_S3_URL+'OutForDelivery.svg');
        }

        div.InfoReceived {
            position: relative;
            top: -5px;
            background-image: url($REACT_APP_S3_URL+'InfoReceived.svg');
        }

        div.FailedAttempt {
            background-image: url($REACT_APP_S3_URL+'FailedAttempt.svg');
        }

        div.Expired {
            background-image: url($REACT_APP_S3_URL+'Expired.svg');
        }

        div.Exception {
            background-image: url($REACT_APP_S3_URL+'Exception.svg');
        }
    }

    td.trackingTimelineInfo {
        padding-left: 12px;
        text-align: left;
        width: 300px;
        padding-top: 0px;
        padding-bottom: 30px;

        .itemLocation {
            // font-weight: $font-bold;
            font-family: Roboto, "-apple-system", "BlinkMacSystemFont", "Segoe UI", Helvetica, Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
            font-size: 20px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.2px;
            color: #212121;
        }

        .trackingMessage {
            // font-weight: $font-regular;
            font-family: Roboto, "-apple-system", "BlinkMacSystemFont", "Segoe UI", Helvetica, Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.14px;
            color: #666666;
            padding-top: 6px;
        }
    }
}

.expandPanelButton {
    padding-top: 19px;
    padding-bottom: 13px;
    padding-left: 9px;
}

.detailDrawerContent {
    padding-left: 32px;
}

.trackingArrow {
    padding-right: 0px;
    float: right;
}

.trackingtagDive {
    display: flex;

    .formAttributeBottom {
        display: inline-block;
        margin-bottom: 40px;
        margin-left: 32px;
    }

    .editformAttributeBottom {
        display: inline-block;
        margin-bottom: 32px;
        margin-left: 32px;
    }

    .tag-position {
        position: absolute;
        margin-top: -12.7rem;
        left: 0.5rem;
        width: 11rem;
        height: 9.2rem;
        background: $white;
        box-shadow: 0 0.125rem 0.625rem 0.313rem rgba(0, 0, 0, 0.05);
        padding: 0.2rem;
        border-radius: 0.5rem;
        z-index: 1;

        .title {
            font-size: 0.875rem;
            font-weight: $font-bold;
            color: $grey-0;
            text-align: center;
            border-bottom: 0.063em solid $grey-3;
            padding: 0;
            line-height: 1.5rem;
            float: left;
            width: 100%;
            // margin-top: 0.1rem;
            padding-bottom: 4px;
            font-family: $font;
            margin-bottom: 5px;
        }

        .circle-picker {
            width: 170px !important;
            margin-left: 10px;
        }

        .huepicker {
            width: 154px !important;
            margin-left: 10px !important;
        }
    }

    .dd-add-tag {
        background: none;
        border: navajowhite;
        font-size: 14px;
        letter-spacing: 0.26px;
        cursor: pointer;
        color: #31A5F7 !important;
        float: right !important;
        margin-top: 0.5rem;
        outline: none;
    }

    .dd-cancel-tag {
        background: none;
        border: navajowhite;
        font-size: 14px;
        letter-spacing: 0.26px;
        cursor: pointer;
        color: $grey-2 !important;
        float: left !important;
        margin-top: 0.5rem;
        outline: none;
    }

    .tagDiv {
        width: 210%;

        .tag {
            height: 32px;
            border-radius: 46px;
            background-color: #e0e0e0;
            margin-right: 8px;
            padding: 6px 12px 0px 12px;
            width: max-content;
            display: inline-block;

            .text {
                height: 16px;
                font-family: Roboto, "-apple-system", "BlinkMacSystemFont", "Segoe UI", Helvetica, Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: 0.14px;
                color: #212121;
            }

            .closeTag {
                -webkit-mask: url(../../../../src/Setup/assets/img/close.svg) no-repeat left top;
                -webkit-mask-image: url(../../../../src/Setup/assets/img/close.svg) no-repeat left top;
                -webkit-mask-size: cover;
                mask: url(../../../../src/Setup/assets/img/close.svg) no-repeat left top;
                mask-image: url(../../../../src/Setup/assets/img/close.svg) no-repeat left top;
                mask-size: cover;
                background-color: #111111;
                height: 0.6rem;
                width: 0.6rem;
                margin-left: 0.5rem;
                cursor: pointer;
                display: inline-block;
            }
        }
    }
}

.expandCheckpoint {
    padding-left: 27px;
}

.trackingListTag {
    height: auto;
    border-radius: 46px;
    background-color: #e0e0e0;
    padding: 8px 12px 8px 12px;
    width: fit-content;

    .text {
        height: 16px;
        font-family: Roboto, "-apple-system", "BlinkMacSystemFont", "Segoe UI", Helvetica, Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.14px;
        color: #212121;
    }
}

.importWrapper {
    margin-top: 20px;

    .ModalContainer {
        width: 589px;
        height: 509px;
        border-radius: 4px;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);
        background-color: #ffffff;
        margin-left: 34px;

        .importSave {
            padding-top: 36.9%;
            padding-left: 10%;
            padding-right: 10%;

            .text {
                font-family: Roboto, "-apple-system", "BlinkMacSystemFont", "Segoe UI", Helvetica, Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
                font-size: 20px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: 0.14px;
                color: #212121;
            }
        }

        .DocProcessWrapper {
            display: -ms-flexbox;
            display: flex;
            width: 1005;
            height: 1005;
            padding-top: 40.5%;
            padding-left: 10%;
            padding-right: 10%;

            .FileIc {
                background: url('https://trackhive-images.s3-ap-southeast-1.amazonaws.com/file_xls.svg') no-repeat 0 0;
                width: 1.825rem;
                height: 2rem;
                margin-right: .6rem;
            }

            .FileName_Process {
                display: flow-root;
                width: 100%;

                .FileName {
                    cursor: default;
                    width: 21.5rem;
                    overflow: hidden;
                    -o-text-overflow: ellipsis;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    text-align: left;
                    float: left;
                    font-family: Roboto, "-apple-system", "BlinkMacSystemFont", "Segoe UI", Helvetica, Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
                    font-size: 20px;
                    font-weight: 700;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: 0.14px;
                    color: #212121;
                }

                a.CloseIc {
                    background: url('https://trackhive-images.s3-ap-southeast-1.amazonaws.com/close-20.svg') no-repeat 50%;
                    width: 1.375rem;
                    height: 1.375rem;
                    display: block;
                    float: right;
                    cursor: pointer;
                }
            }
        }

        .DragDropWrapper {
            position: relative;
            width: 100%;
            height: 30rem;

            .box {
                height: 100%;
                width: 100%;
                cursor: pointer;

                .box-content-inner {
                    bottom: 32%;
                    left: 36.1%;
                    text-align: center;
                    position: absolute;

                }

                input {
                    position: absolute;
                    opacity: 0;
                    cursor: pointer;
                    width: 593px;
                    height: 540px;
                    top: 0;
                    left: 0;
                }
            }
        }
    }

    .label-dnd {
        font-family: Roboto, "-apple-system", "BlinkMacSystemFont", "Segoe UI", Helvetica, Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
        font-size: 30px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.3px;
        color: #212121;
    }

    .label-browse {
        font-family: Roboto, "-apple-system", "BlinkMacSystemFont", "Segoe UI", Helvetica, Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.14px;
        color: #212121;
    }

    .modalText {
        font-size: .85em;
        font-weight: $font-medium;
        color: $grey-0;
        text-align: center;
        line-height: 1.5em;
        padding-bottom: 2.125em;
    }
}

.exprtWrapper {
    margin-left: 32px;
    width: 483px;

    .ModalText {
        font-family: Roboto, "-apple-system", "BlinkMacSystemFont", "Segoe UI", Helvetica, Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
        font-size: 20px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.2px;
        color: #212121;
    }

    .smallText {
        font-family: Roboto, "-apple-system", "BlinkMacSystemFont", "Segoe UI", Helvetica, Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.14px;
        color: #666666;
    }

    .ModalList {
        ul {
            padding: 0;
            // width: 60%;
            margin: 0 auto;

            li {
                list-style: none;

                label {
                    cursor: pointer;
                    font-family: Roboto, "-apple-system", "BlinkMacSystemFont", "Segoe UI", Helvetica, Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
                    font-size: 16px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: 0.16px;
                    color: #5e6367;
                    -webkit-touch-callout: none;
                    /* iOS Safari */
                    -webkit-user-select: none;
                    /* Safari */
                    -khtml-user-select: none;
                    /* Konqueror HTML */
                    -moz-user-select: none;
                    /* Firefox */
                    -ms-user-select: none;
                    /* Internet Explorer/Edge */
                    user-select: none;

                    /* Non-prefixed version, currently */
                    input[type=radio] {
                        margin: 0 0.7em 0 0;
                        height: auto
                    }
                }
            }

            .disabled {
                opacity: 0.5;
                pointer-events: none;
            }
        }
    }
}

.bulkfilterActionSpan {
    height: 32px;
    border-left: 5px solid #111111;
}

.tagsText {
    height: 23px;
    font-family: Roboto, "-apple-system", "BlinkMacSystemFont", "Segoe UI", Helvetica, Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.2px;
    color: #212121;
    padding-top: 30px;
    padding-bottom: 30px
}