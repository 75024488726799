@import '_common';

$v-middle:middle;

* {
    box-sizing: inherit
}

a {
    outline: none;
}

html {
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: fixed;
}

body {
    margin: 0;
    padding: 0;
    font-family: $font;

    font-weight: $font-medium;
    -webkit-overflow-scrolling: touch;
    height: 100%;
    overflow: hidden;
}

#root {
    width: 100%;
    float: left;

}

.bg-Gray {
    background: $background-grey;
}

.MuiSwitch-colorPrimary.Mui-checked {
    color: #1a73e8 !important
}

.MuiSwitch-colorPrimary.Mui-checked+.MuiSwitch-track {
    background-color: #bedaff !important
}

.integrateH2Style {
    margin-left: 32px;
    margin-bottom: 0px;
    margin-top: 26px;
    color: #212121;
}

.sync-img {
    width: 19px;
    height: 19px;
    position: absolute
}

.sync-progress {
    margin-left: 25px;
}

.SearchInput {
    position: absolute;
    top: 9px;
    right: 129px;
    z-index: 10;

    ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #727272;
        opacity: 0.54;
        font-family: Roboto, "-apple-system", "BlinkMacSystemFont", "Segoe UI", Helvetica, Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
        font-size: 14px;
    }

    input {
        width: 22rem;
        height: 100%;
        background: #ffffff url($REACT_APP_S3_URL+'searchBarIcon.svg') no-repeat 4px 2px;
        border: 0;
        border-width: 0 0 1px 0;
        padding: 0.4rem 0.4rem 0.4rem 2.2rem;
        outline: 0;
        font-size: 0.875rem;
        color: #434A54;
        border-radius: 4px;
    }
}

.headerWrapper {
    a:hover {
        background-color: #e3eefc;
    }
}

.iconWidthWrapper {
    .MuiListItemIcon-root {
        min-width: 0px !important;
    }
}

.textWrapper {
    .MuiListItemText-root {
        margin-top: 0px !important;
        margin-bottom: 0px !important;
    }

}

.applicationMainWrapper {
    width: 100%;
    float: left;
    margin: 0;
    font-family: $font;
    display: flex;

    .headerRightOptions {
        float: right;
        margin-top: 0.3rem;
        width: 10.3rem;
    }

    #applicationRightPanel {
        float: left;
        width: calc(100% - 73px);
        height: calc(100vh - 60px);
        background: $background-grey;

        .headerRight {
            float: right;
            margin-top: 0.3rem;
            width: 10.3rem;

            .show {
                outline: none;
                opacity: 1;
                pointer-events: auto;
                display: block !important;
            }

            .top-right-user-dd.show a.profile_ic {
                background: url($REACT_APP_S3_URL+'headerUserActivePic.svg') no-repeat center center #BFC2C7;
                border-radius: 0.25rem;
            }

            .top-right-user-dd-btn,
            .top-right-user-dd-item {
                background: none;
                border: none;
                cursor: pointer;
                padding: 0;
            }

            a.notification_ic,
            a.profile_ic {
                background: url($REACT_APP_S3_URL+'headerUserDefaultPic.svg') no-repeat center center;
                height: 2.25rem;
                width: 2.85rem;
                display: block;
                float: left;

                &:hover {
                    // background-color: #EBECED;
                    background-color: $blue-5;
                    border-radius: 0.25rem;
                }
            }

            a.notification_ic {
                background: url(../img/notifications.svg) no-repeat center center;
            }

            .notification-bell.show a.notification_ic {
                background: url(../img/notifications-active-36p.svg) no-repeat center center $grey-2;
                border-radius: 4px;
            }

            .top-right-notification-dd {
                float: right;
                margin-left: 0.3rem;
            }

            .top-right-user-dd {
                float: right;
                margin-left: 0.3rem;

                .dropdown-menu {
                    left: -0.2rem !important;
                    top: 7px !important;
                    width: 8.5rem !important;
                    min-height: 6.2rem !important;
                    right: 90px;
                    background: #EBECED !important;
                    padding-top: 10px;
                    -webkit-box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.05), 0 2px 10px 5px rgba(0, 0, 0, 0.1) !important;
                    box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.05), 0 2px 10px 5px rgba(0, 0, 0, 0.1) !important;
                    border-radius: 4px;
                    padding-bottom: 20px;
                    z-index: 1000;
                    min-width: 160px !important;
                    display: none;
                }

                .dd-username {
                    font-size: 14px;
                    color: $grey-0;
                    letter-spacing: 0;
                    line-height: 20px;
                    font-weight: 700;
                    text-align: center;
                }

                button.commonButton {
                    border-radius: 4px;
                    color: $white;
                    width: 7.5rem;
                    height: 2rem;
                    display: block;
                    margin: 0 auto;
                    line-height: 28px;
                    padding: 0px;
                    margin-top: 10px;
                }

                button.dd-profile {
                    background: $grey-1;
                }

                button.dd-logout {
                    background: $red-0;
                }
            }

            .contactUsIcon {
                float: right;
                cursor: pointer;

                &:hover {
                    background-color: #415277;
                    border-radius: 0.25rem;
                }

                img {
                    height: 2.25rem;
                    width: 2.85rem;
                    display: block;
                }
            }
        }

        .clearfix {
            content: "";
            clear: both;
            display: table;
        }

        .loginAndRegistrationCommonDiv {
            width: 452px;
            margin: 100px auto 50px auto;

            .leftBtnDiv {
                width: 220px;
            }

            .rightBtnDiv {
                width: 140px;
                text-align: right;
            }

            .logoTagLineMainDiv {
                display: inherit;
                margin-bottom: 1.375em;

                .logoDiv {
                    background-position: 0;
                    background-repeat: no-repeat;
                    background-size: contain;
                    width: 14rem;
                    height: 3rem;
                    margin: 0 auto;

                    img {
                        max-width: 100%;
                    }
                }
            }
        }

        .commonTopHeader {
            display: flow-root;
            padding-bottom: 0.65rem;
            border-bottom: 1px solid $grey-3;
            background: $light-blue;
            padding-top: 0.63rem;
            height: 48px !important;

            ::placeholder {
                color: #FFFFFF;
                opacity: 1;
                /* Firefox */
            }

            :-ms-input-placeholder {
                /* Internet Explorer 10-11 */
                color: #FFFFFF;
            }

            ::-ms-input-placeholder {
                /* Microsoft Edge */
                color: #FFFFFF;
            }

            .headerText {
                margin: 0.18rem 1.30rem 0.3rem 0.7rem;
                float: left;
                font-weight: 700;
                color: $white;
                font-size: 1.125rem;
                line-height: 1.25em;
                position: absolute;
                z-index: 100;
            }

            h1 {
                margin: 0.18rem 1.30rem 0.3rem 0.7rem;
                float: left;
                font-weight: 700;
                color: $white;
                font-size: 1.125rem;
                line-height: 1.25em;

                .trackingIcon {
                    height: 1.4rem;
                    display: block;
                    float: left;
                    background: transparent;
                    width: 0;
                }

                .dashboardIcon {
                    height: 1.4rem;
                    display: block;
                    float: left;
                    background: transparent;
                    width: 0;
                }

                .automationIcon {
                    height: 1.4rem;
                    display: block;
                    float: left;
                    background: transparent;
                    width: 0;
                }

                .settingsIcon {
                    height: 1.4rem;
                    display: block;
                    float: left;
                    background: transparent;
                    width: 0;
                }

                .integrationIcon {
                    height: 1.4rem;
                    display: block;
                    float: left;
                    background: transparent;
                    width: 0;
                }
            }

            .headerSearchDiv {
                .searchWrapper {
                    width: 25.1rem;
                    margin: 0.3rem 0px 0.1rem 1rem;
                    height: 1.75rem;
                    background-color: $white;
                    position: relative;
                }

            }

            #SearchWrap {
                position: fixed;
                top: 8px;
                left: 225px;
                margin: 0.3rem 0 0.1rem 2rem;

                width: 22rem;
                background: url($REACT_APP_S3_URL+'searchIcon.svg') no-repeat 0.2em -0.05rem;
                border-bottom: 1px solid #BFC2C7;
                border-width: 0 0 1px 0;
                padding: 0.2rem 0.5rem 0.2rem 2rem;
                outline: 0;
                font-size: 0.875rem;
                -webkit-appearance: none;
                height: 1.4rem;
                color: #FFFFFF;

            }

            .search-input {
                input:focus {
                    border-bottom: 1px solid #31A5F7 !important;
                    color: #434A54;
                }
            }
        }

        .commonFooter {
            footer {
                height: 56px;
                padding: 0.65rem 0 0 0 !important;
                background: $white;
                position: fixed;
                bottom: 0;
                left: 0;
                z-index: 100;
                text-align: center;
                float: left;
                margin-left: 72px;
                width: calc(100% - 60px);
                box-shadow: 0 0.02rem 0.3rem 0.05rem rgba(0, 0, 0, .3);
                -webkit-box-shadow: 0 0.02rem 0.3rem 0.05rem rgba(0, 0, 0, .3);

                .centerItems {
                    display: inline-flex;

                    .bottomPrimaryButton {
                        background: $dark-blue-1;
                        width: 9rem;
                        height: 2.4rem;
                        text-decoration: none;
                        display: flow-root;
                        text-align: center;
                        float: left;
                        margin-left: 2rem;
                        cursor: pointer;
                        outline: none;
                        border-radius: 0.25rem;

                        img {
                            margin: 0.18rem 0.625rem 0 0;
                            float: left;
                            height: 32px
                        }

                        span {
                            color: $white;
                            font-size: 0.875rem;
                            display: inline-block;
                            line-height: 1rem;
                        }

                        &:hover {
                            background: #405786;
                        }

                        span.buttonText {
                            padding: 0.65rem 0;
                        }
                    }

                    a.bottomPrimaryButton.automation_btn {
                        padding: 0 10px;
                    }
                }
            }
        }


        .customPreviewPageMainContainer {
            margin: 0 auto;
            width: 100%;
            height: 100vh;
            overflow-x: hidden;
            overflow-y: auto;
            background: $grey-6;

            body {
                height: 100vh;
                overflow-y: auto;
                overflow-x: hidden;
            }



            .trackingHistoryTimelineInnerDiv {
                margin-left: -10rem;

                table {
                    border-spacing: 0;
                    width: 100%;

                }

                td {
                    padding: 16px 0;
                    border: none;
                    text-align: center;
                    vertical-align: top;
                }

                td.trackingTimelineDateTimeValue {
                    color: #555;
                    font-size: 14px;
                    width: 100px;
                    vertical-align: top;
                    text-align: right;

                    p {
                        margin: 0;
                        color: $grey-0;
                        font-weight: $font-bold
                    }

                    p.time {
                        color: $grey-1;
                        font-weight: $font-medium;
                        font-size: $font-label;
                    }
                }

                .trackingTimelineStatusIcon {
                    width: 50px;
                    vertical-align: top;
                    background-image: url($REACT_APP_S3_URL+ 'status_new.png');
                    background-repeat: no-repeat;
                    background-position: -87px;

                    &:last-child {
                        background-position: 160px 0;
                    }

                    div {
                        height: 30px;
                        width: 30px;
                        background-image: url($REACT_APP_S3_URL+'track_status_icon.png');
                        margin-left: auto;
                        margin-right: auto;
                        background-size: contain;
                    }

                    div.InTransit {
                        background-position: 60px 0px;
                        background-image: url($REACT_APP_S3_URL+'InTransit.svg');
                    }

                    div.Delivered {
                        background-position: 30px 0px;
                        background-image: url($REACT_APP_S3_URL+'Delivered.svg');
                    }

                    div.Pending {
                        background-position: 120px 0px;
                        background-image: url($REACT_APP_S3_URL+'Pending.svg');
                    }

                    div.OutForDelivery {
                        background-position: 150px 0px;
                        background-image: url($REACT_APP_S3_URL+'OutForDelivery.svg');
                    }

                    div.InfoReceived {
                        background-position: 150px 0px;
                        position: relative;
                        top: -5px;
                        background-image: url($REACT_APP_S3_URL+'InfoReceived.svg');
                    }

                    div.FailedAttempt {
                        background-position: 150px 0px;
                        background-image: url($REACT_APP_S3_URL+'FailedAttempt.svg');
                    }

                    div.Expired {
                        background-position: 150px 0px;
                        background-image: url($REACT_APP_S3_URL+'Expired.svg');
                    }

                    div.Exception {
                        background-position: 150px 0px;
                        background-image: url($REACT_APP_S3_URL+'Exception.svg');
                    }
                }

                td.trackingTimelineInfo {
                    color: $grey-0;
                    font-size: $font-input;
                    text-align: left;

                    .itemLocation {
                        font-weight: $font-bold;
                    }

                    .trackingMessag {
                        font-weight: $font-regular;
                    }
                }
            }
        }

        .trackingPublicPageMainContainerDiv {
            height: 100vh;
            background: #eff0f1;
            overflow-x: auto;

            .trackingPublicPageHeaderDiv {
                padding: 1rem 3rem;
                text-align: center;
                border-bottom: 1px solid rgb(236, 240, 241);

                .headerTitle {
                    color: rgb(27, 186, 225);
                    font-size: 24px;
                    font-weight: 600;
                    margin: 0px;
                }
            }

            .trackingPublicPageBodyMainDiv {
                width: 100%;
                overflow-y: auto;
                overflow-x: hidden;

                html {
                    position: inherit !important;
                }

                .trackingHistoryTimelineInnerDiv {
                    margin-left: -1rem;

                    table {
                        border-spacing: 0
                    }

                    td {
                        padding: 16px 0;
                        border: none;
                        text-align: center;
                        vertical-align: top;

                        &:first-child {
                            background: none !important;
                        }
                    }

                    td.trackingTimelineDateTimeValue {
                        color: #555;
                        font-size: 14px;
                        width: 100px;
                        vertical-align: top;
                        text-align: right;

                        p {
                            margin: 0;
                            color: $grey-0;
                            font-weight: $font-bold
                        }

                        p.time {
                            color: $grey-1;
                            font-weight: $font-medium;
                            font-size: $font-label;
                        }
                    }

                    .trackingTimelineStatusIcon {
                        width: 50px;
                        vertical-align: top;
                        background-image: url($REACT_APP_S3_URL+ 'status_new.png');
                        background-repeat: no-repeat;
                        background-position: -125px;

                        &:last-child {
                            background-position: 160px 0;
                        }

                        div {
                            height: 30px;
                            width: 30px;
                            background-image: url($REACT_APP_S3_URL+'track_status_icon.png');
                            margin-left: auto;
                            margin-right: auto;
                            background-size: contain;
                        }

                        div.InTransit {
                            background-position: 60px 0px;
                            background-image: url($REACT_APP_S3_URL+'InTransit.svg');
                        }

                        div.Delivered {
                            background-position: 30px 0px;
                            background-image: url($REACT_APP_S3_URL+'Delivered.svg');
                        }

                        div.Pending {
                            background-position: 120px 0px;
                            background-image: url($REACT_APP_S3_URL+'Pending.svg');
                        }

                        div.OutForDelivery {
                            background-position: 150px 0px;
                            background-image: url($REACT_APP_S3_URL+'OutForDelivery.svg');
                        }

                        div.InfoReceived {
                            background-position: 150px 0px;
                            position: relative;
                            top: -5px;
                            background-image: url($REACT_APP_S3_URL+'InfoReceived.svg');
                        }

                        div.FailedAttempt {
                            background-position: 150px 0px;
                            background-image: url($REACT_APP_S3_URL+'FailedAttempt.svg');
                        }

                        div.Expired {
                            background-position: 150px 0px;
                            background-image: url($REACT_APP_S3_URL+'Expired.svg');
                        }

                        div.Exception {
                            background-position: 150px 0px;
                            background-image: url($REACT_APP_S3_URL+'Exception.svg');
                        }
                    }

                    td.trackingTimelineInfo {
                        color: $grey-0;
                        font-size: $font-input;
                        text-align: left;

                        .itemLocation {
                            font-weight: $font-bold;
                        }

                        .trackingMessag {
                            font-weight: $font-regular;
                        }
                    }
                }
            }
        }

        .noTrackingDataStyleDiv {
            height: auto;
            text-align: center;
            color: rgb(255, 255, 255);
            line-height: 1.6;
            text-transform: none;
            background-color: rgb(210, 103, 89);
            padding: 20px;
            margin: 1rem 10rem;
        }

        .trackingLoaderDiv {
            display: flex;
            height: 100vh;
            justify-content: center;
            align-content: center;
        }

        .trackingPageWithSearchMainDiv {
            .wrapperDiv {
                .headerDiv {
                    padding: 0rem 1.55rem;
                    border-bottom: thin solid #E9E9E9;
                    position: relative;
                    font-size: 0;
                    background: #ffffff;
                    -webkit-box-shadow: 0px 1px 5px -3px rgba(173, 173, 173, 0.61);
                    -moz-box-shadow: 0px 1px 5px -3px rgba(173, 173, 173, 0.61);
                    box-shadow: 0px 1px 5px -3px rgba(173, 173, 173, 0.61);

                    .templateHeaderWrapper {
                        max-width: 1024px;
                        margin: 0 auto;

                        .logoDiv {
                            display: inline-block;
                            vertical-align: middle;
                            width: 50%;

                            img {
                                max-width: 100px;
                            }
                        }

                        .supportNumberMainDiv {
                            width: 50%;
                            text-align: right;
                            display: inline-block;
                            vertical-align: middle;

                            .supportLabel {
                                color: #BFC2C7;
                                font-size: 1rem;
                                line-height: 1.5;
                            }

                            .supportNumber {
                                color: #434A54;
                                font-size: 1rem;
                                font-weight: 600;
                            }
                        }
                    }
                }

                .searchMainBodyDiv {
                    width: 100%;

                    .searchInnerDiv {
                        max-width: 640px;
                        padding: 0px;
                        margin: 50px auto 20px;

                        .textBoxAndButtonMainDiv {
                            margin-top: 1rem;

                            .textBoxAndButtonInnerDiv {
                                .inputDiv {
                                    display: inline-block;
                                    vertical-align: top;
                                    width: 74%;
                                }

                                .buttonDiv {
                                    display: inline-block;
                                    vertical-align: top;
                                    width: 25%;

                                    img {
                                        display: inline-block;
                                        width: 24px;
                                        height: 32px;
                                        margin-right: 2px;
                                        vertical-align: middle;
                                    }

                                    span {
                                        vertical-align: middle;
                                    }
                                }
                            }
                        }
                    }
                }
            }

        }
    }

    // applicationRightPanel close

    .page-not-found {
        position: fixed;
        top: 50%;
        left: calc(50% + (10rem / 2));
        -webkit-transform: translate(-50%, -53%);
        -ms-transform: translate(-50%, -53%);
        transform: translate(-50%, -53%);
        text-align: center;
        width: 39rem;

        .page-not-found-span {
            color: #BFC2C7;
            font-size: 1.88rem;
            line-height: 2.44rem;
            display: inline-block;
        }

        .page-not-found-desc-span {
            margin-top: 0.88rem;
            height: 1.75rem;
            font-size: 1.13rem;
            line-height: 1.75rem;
            color: #BFC2C7;
            display: inline-block;
        }
    }

    .grecaptcha-badge {
        display: none;
    }

    #notification-block {
        background: $white;
        box-shadow: 0 2px 10px 5px rgba(0, 0, 0, 0.05);
        border-radius: 0.25rem;
        width: 17.5rem;
        min-height: 3.5rem;
        display: block;
        padding-bottom: 0.3125rem;
        overflow: hidden;
        right: 1.125rem;
        top: 3.875rem;
        position: fixed;

        .notify_block_container {
            overflow: hidden;
            margin-top: 0.625rem;
        }

        .notify_block_img {
            width: 2.875rem;
            height: 2.875rem;
            float: left;
            display: block;

            img {
                float: left;
                margin-left: 0.375rem;
                margin-top: 0.1875rem;
            }
        }

        .notify_block_txt {
            width: 13.875rem;
            float: left;
            font-weight: $font-medium;
            font-size: 0.875rem;
            color: $grey-0;
            letter-spacing: 0.26px;
            line-height: 16px;
        }
    }

    #notification-block-fadeout {
        display: none;
    }

}

// applicationMainWrapper close